import React, { useState } from 'react';
import TableCellFilter from '@/module/common/componentUI/TableCellFilted';
import TableContainer from '@/components/Table/TableContainer';

import { TableHead, TableRow, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import allUsersDispatcher from '../../action';
import moment from 'moment';
import { FAMILY_GROUP_COLUMN } from '../../constants';
import CustomTableCell from '@/components/TableCell/TableCell';

const useStyles = makeStyles({
  customCellFilterd: {
    borderColor: '#F6F7FA',
    padding: '16px 0'
  }
});

const FamilyGroup = ({ userId, handleViewDependantUser }) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [paging, setPaging] = useState({
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  });

  const [hiddenRows, setHiddenRows] = useState({});

  const fetchData = paging => {
    allUsersDispatcher.getFamilyGroups({ userId, paging }, result => {
      const { list: newList, ...rest } = result;
      setList(newList);
      setPaging(rest);
    });
  };

  const TableCellFilterWrapper = ({ ...props }) => {
    return (
      <TableCellFilter
        {...{
          hiddenRows,
          setHiddenRows
        }}
        {...props}
      />
    );
  };

  const queryData = (currentSearch, currentPaging, sortOption) => {
    setPaging(currentPaging);
    fetchData(currentPaging);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {FAMILY_GROUP_COLUMN.map(({ label, stateValue }) => {
          return (
            <TableCellFilterWrapper
              className={classes.customCellFilterd}
              label={label}
              stateValue={stateValue}
              key={label}
            >
              {label}
            </TableCellFilterWrapper>
          );
        })}
      </TableRow>
    </TableHead>
  );

  const TableCells = row => (
    <React.Fragment key={row.id}>
      <CustomTableCell
        style={{ cursor: 'pointer' }}
        width="12%"
        disablePaddingX
        onClick={() => handleViewDependantUser(row)}
      >
        {!hiddenRows.fullName && row.fullName}
      </CustomTableCell>
      <CustomTableCell disablePaddingX width="12%">
        {!hiddenRows.identityNumber && row.identityNumber}
      </CustomTableCell>
      <CustomTableCell disablePaddingX width="12%">
        {!hiddenRows.patientShortId && row.patientShortId}
      </CustomTableCell>
      <CustomTableCell disablePaddingX width="12%">
        {!hiddenRows.dateOfBirth &&
          moment(row.dateOfBirth).format('MM/DD/YYYY')}
      </CustomTableCell>
      <CustomTableCell disablePaddingX>
        {!hiddenRows.gender && row.gender}
      </CustomTableCell>
    </React.Fragment>
  );

  return (
    <Box padding="22px 20px 0">
      <TableContainer
        data={list}
        header={TableHeader}
        query={queryData}
        paging={paging}
        renderRow={TableCells}
        isSearch={false}
        toolbar={false}
        elevation={0}
      />
    </Box>
  );
};

export default FamilyGroup;
