import React from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/styles';
import { startCase } from 'lodash';

const useStyles = makeStyles(theme => ({
  iFrame: {
    width: 1080,
    aspectRatio: '16/9',
    border: 0
  },
  dialogContent: {
    padding: 0
  }
}));

const dialogTitleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: 400
  },
  title: {
    fontWeight: 600
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const CustomDialogTitle = withStyles(dialogTitleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export default function PreviewPdfDialog({ pdfLink, open, onClose, title }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <CustomDialogTitle onClose={onClose}>
        {startCase(title)}
      </CustomDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <iframe
          id="preview-pdf-id"
          title={title}
          className={classes.iFrame}
          src={`${pdfLink}#toolbar=0`}
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </DialogContent>
    </Dialog>
  );
}
