import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Autorenew } from '@material-ui/icons';
import { Grid, Button, withStyles, SvgIcon, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CustomDate from '@/components/CustomDate';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import CustomSelectContainer from '@/components/CustomSelect/CustomSelectContainer';
import moment from 'moment';

import { AppointmentStatusIcon } from '@/assets/svg';
import { AllUsersAppointmentType } from '@/module/appointment-management/constants';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: 16,
    marginTop: 20
  }
}));

const CustomButton = withStyles({
  root: {
    margin: 5,
    paddingLeft: 40,
    paddingRight: 40,

    height: 40,
    minWidth: 160,

    textTransform: 'unset',
    fontWeight: 600,
    borderRadius: 50
  }
})(Button);

const GroupSearch = ({
  filterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  setFilterOption,
  onResetFilterOps,
  defaultStartDate = ''
}) => {
  const classes = useStyles();
  const onChangeStartDate = value => {
    const newValue = moment(value).startOf('date');

    setFilterOption({
      ...filterOption,
      startDate: value ? newValue : defaultStartDate
    });
  };
  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every(key => isEmpty(filterOption[key]));
  }, [filterOption]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: filterOption.startDate,
              onChange: onChangeStartDate,
              placeholder: 'Date'
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={filterOption.appointmentTypes}
            data={AllUsersAppointmentType}
            StartAdornment={() => (
              <SvgIcon
                component={AppointmentStatusIcon}
                style={{ marginRight: 8 }}
              />
            )}
            renderValue={
              <>
                {filterOption.appointmentTypes
                  .map(status => {
                    return AllUsersAppointmentType[status];
                  })
                  .join(', ') || 'Appointment Type'}
              </>
            }
            onClear={onFilterOptionClear('appointmentTypes', [])}
            onChange={onFilterOptionChange('appointmentTypes')}
          />
        </Grid>
        <Grid container justify="flex-end" style={{ marginRight: 16 }}>
          <CustomButton
            onClick={() => {
              onResetFilterOps();
            }}
            variant="contained"
            color="primary"
            endIcon={<Autorenew />}
            style={{ borderRadius: 50, margin: 5 }}
            disabled={isEmptyFilterParams}
          >
            Reset Filter
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  max-width: 160px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 12px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
      color: #cacfd3;
    }
  }
`;

export default GroupSearch;
