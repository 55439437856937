import React from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { get, upperCase, isEmpty } from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';

const DrugAllergyItem = ({
  data = {},
  title,
  classes,
  isEdit,
  medicalInfo,
  setMedicalInfo
}) => {
  const SELECT_ANSWERS = [
    { label: 'Yes', value: true },
    { label: 'No ', value: false }
  ];
  const handleChange = e => {
    const { name, value } = e.target;
    setMedicalInfo({
      ...medicalInfo,
      drugAllergy: {
        ...medicalInfo.drugAllergy,
        [name]: value
      }
    });
  };

  return (
    <>
      {!isEmpty(data) && (
        <>
          <Grid item xs={12}>
            <DividerHeader title={upperCase(title)} />
          </Grid>
          <Grid item xs={6}>
            {isEdit ? (
              <TextField
                fullWidth
                select
                label="Drug Allergy:"
                name="drugAllergy"
                onChange={handleChange}
                value={get(data, `drugAllergy`)}
              >
                {SELECT_ANSWERS.map((item, inxItem) => (
                  <MenuItem key={inxItem} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <WrappedItem>
                <RowItem key={data.callHistoryId}>
                  <span className={classes.title}>Drug Allergy:</span>
                  <span>{get(data, `drugAllergy`) ? 'Yes' : 'No'}</span>
                </RowItem>
              </WrappedItem>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default DrugAllergyItem;
