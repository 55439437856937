import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  InputLabel,
  Checkbox,
  withStyles
} from '@material-ui/core';
import { findIndex, isEmpty, startCase, upperCase } from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';
import { ANSWER_TYPES } from '../../constants';
import { questionnairesDispatcher } from '@/module/screening-questionnaires';
import { useSelector } from 'react-redux';
import { mergeArrayByKey } from '@/helpers';

const CustomTextField = withStyles({
  root: {
    pointerEvents: 'none',
    '& label + .MuiInput-formControl': {
      marginTop: 6
    },
    '& .MuiInputLabel-formControl': {
      top: -7
    }
  }
})(TextField);

const MedicalItem = ({
  data = [],
  classes,
  title,
  isEdit,
  medicalInfo,
  setMedicalInfo,
  gender
}) => {
  const { list: defaultList } = useSelector(
    state => state.screeningQuestionnaires
  );

  const handleChange = (data, selectedValue = {}) => e => {
    const { value, name, checked } = e.target;

    const questionnaires = medicalInfo.questionnaire?.questionnaires.map(
      item => {
        let answer = item;
        if (item.question === data.question) {
          let suggestionByGenders = [];
          switch (data.type) {
            case ANSWER_TYPES.Radio:
              suggestionByGenders = value?.suggestionByGenders?.filter(
                item => item.gender === gender
              );
              const valueRadio = {
                ...value,
                selected: true,
                suggestionByGenders
              };
              answer = {
                ...item,
                [name]: [].concat(valueRadio)
              };
              break;

            case ANSWER_TYPES.Checkbox:
              suggestionByGenders = selectedValue?.suggestionByGenders?.filter(
                item => item.gender === gender
              );

              let valueCheckbox = {};
              let newAnswers = [...item.answers];
              const index = findIndex(
                newAnswers,
                ans => ans.answer === selectedValue.answer
              );

              if (checked) {
                valueCheckbox = {
                  ...selectedValue,
                  selected: checked,
                  suggestionByGenders
                };
                newAnswers = newAnswers.concat(valueCheckbox);
              } else {
                newAnswers.splice(index, 1);
              }
              answer = {
                ...item,
                [name]: newAnswers
              };

              break;

            case ANSWER_TYPES.FreeText:
              answer = { ...item, [name]: value };
              break;

            default:
              break;
          }
        }
        return answer;
      }
    );

    setMedicalInfo({
      ...medicalInfo,
      questionnaire: {
        ...medicalInfo.questionnaire,
        questionnaires
      }
    });
  };

  useEffect(() => {
    let paging = {
      pageIndex: null,
      pageSize: null
    };
    questionnairesDispatcher.getData('', paging);
  }, []);

  const ViewMedicalItem = ({ data }) => {
    if (isEmpty(data)) return null;

    let answer = '';
    switch (data.type) {
      case ANSWER_TYPES.Radio:
        answer = data.answers[0].answer;
        break;
      case ANSWER_TYPES.Checkbox:
        answer = data.answers.map(ans => ans.answer).join(', ');
        break;

      case ANSWER_TYPES.FreeText:
        answer = data.patientAnswer;
        break;

      default:
        break;
    }
    return (
      <RowItem>
        <span className={classes.title}>{startCase(data.question)}:</span>
        <span>{answer}</span>
      </RowItem>
    );
  };

  const renderData = () => {
    const result = [];

    data.forEach((item, inx) => {
      const rowItem = (
        <Grid item xs={6} key={`${item.question}-${inx}`}>
          <WrappedItem>
            <ViewMedicalItem data={item} />
          </WrappedItem>
        </Grid>
      );
      result.push(rowItem);
    });
    return result;
  };

  const renderUpdateData = () => {
    const result = [];
    // List: Default data with full options
    // Data: Existed data with only option selected
    const newList = mergeArrayByKey(data, defaultList, 'question');
    let component;

    newList.forEach((item, inx) => {
      // Find question with data selected
      const question = data.find(d => d.question === item.question);

      switch (item.type) {
        case ANSWER_TYPES.Radio:
          const selectedValueRadio = item?.answers.find(
            ans => ans.answer === question.answers[0].answer
          );
          component = (
            <TextField
              fullWidth
              select
              label={`${startCase(item.question)}:`}
              value={selectedValueRadio}
              name="answers"
              onChange={handleChange(item)}
            >
              {item.answers.map((ans, inxItem) => (
                <MenuItem key={inxItem} value={ans}>
                  {ans.answer}
                </MenuItem>
              ))}
            </TextField>
          );
          break;

        case ANSWER_TYPES.Checkbox:
          const newList = mergeArrayByKey(
            item?.answers,
            question.answers,
            'answer'
          );
          component = (
            <>
              <InputLabel>{startCase(item.question)}:</InputLabel>
              {newList.map(ans => (
                <div style={{ position: 'relative' }}>
                  <CustomTextField fullWidth label={ans.answer} />
                  <Checkbox
                    name="answers"
                    color="primary"
                    className={classes.checkBox}
                    defaultChecked={ans.selected}
                    onChange={handleChange(item, ans)}
                  />
                </div>
              ))}
            </>
          );
          break;

        case ANSWER_TYPES.FreeText:
          component = (
            <TextField
              fullWidth
              name="patientAnswer"
              label={`${startCase(item.question)}:`}
              value={item.patientAnswer}
              onChange={handleChange(item)}
            />
          );
          break;

        default:
          break;
      }
      const rowItem = (
        <Grid item xs={6} key={`${item.question}-${inx}`}>
          {component}
        </Grid>
      );
      result.push(rowItem);
    });
    return result;
  };

  return (
    <>
      {!isEmpty(data) && (
        <>
          <Grid item xs={12}>
            <DividerHeader title={upperCase(title)} />
          </Grid>

          {isEdit ? renderUpdateData() : renderData()}
        </>
      )}
    </>
  );
};

export default MedicalItem;
