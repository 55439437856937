import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  withStyles,
  Tooltip,
  makeStyles,
  Checkbox,
  TextField,
  InputAdornment
} from '@material-ui/core';
import {
  MoreVertRounded,
  Visibility,
  Check,
  Cancel,
  GetApp,
  VisibilityRounded,
  Edit,
  CancelOutlined,
  Info,
  LibraryAddCheck,
  Cached,
  Autorenew,
  Delete,
  FileCopyOutlined
} from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import DeleteModal from '@/components/DeleteModal';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import CustomTooltip from '@/components/CustomTooltip';
import GroupSearch from './GroupSearch';
import globalDispatcher from '@/redux/global/actions';
import { teleAppoinmentDispatcher } from '..';

import { urlLabel } from '../../../enum/PermissionEnum';
import MenuAction from '../../../components/MenuAction';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import TableContainer from '../../../components/Table/TableContainer';
import {
  appointmentColNames,
  defaultAppointmentCols,
  STATUSES_APPOINTMENT
} from '../constants';
import EditAppointment from '../component/EditAppointment';

import { StatusComponent } from './StatusComponent';
import {
  AllUsersAppointmentType,
  AppointmentStatus,
  AppointmentType,
  TYPE_MODAL
} from '@/module/appointment-management/constants';
import { PdfExport } from '@/components/PdfExport';
import {
  CheckedIcon,
  ConsentFormActive,
  ConsentFormInactive,
  PaymentActive,
  PaymentInactive,
  SurveyActive,
  SurveyInative,
  PhysicalExamActive,
  PhysicalExamInactive
} from '@/assets/svg';
import allUsersDispatcher from '@/module/all-users/action';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import { ROLES_NAMES_SPECIAL } from '@/constants/roles';
import AppointmentForm from '@/module/appointment-management/component/AppointmentForm';
import { appointmentManagementDispatcher } from '@/module/appointment-management';
import { authDispatcher } from '@/module/auth';
import customToast from '@/new-components/CustomNotification';

const TableCellSticky = withStyles({
  root: {
    position: 'sticky',
    background: 'white',
    right: 0,
    // borderLeft: '1px solid #e0e0e0',
    width: 80
  }
})(TableCell);

const useStyle = makeStyles({
  wrapperIcon: {
    position: 'relative',
    width: 30,
    maxWidth: 30,
    marginRight: 16
  },
  checkedIcon: {
    position: 'absolute',
    right: -7,
    top: -3
  }
});

const TeleAppointmentManagement = ({
  userId,
  showToolbar,
  headerCols,
  hideExportExcel = false
}) => {
  const history = useHistory();
  const classes = useStyle();
  const { list: data, paging } = useSelector(state => state.teleAppoinment);
  const {
    listAllUsersAppointment: dataAllUsers,
    paging: pagingAllUsers
  } = useSelector(state => state.allUsers);

  const { listAvailableUsers } = useSelector(
    state => state.appointmentManagement
  );

  const { locations: listLocations } = useSelector(state => state.globalState);
  const { userInfo, userPasscode } = useSelector(state => state.auth);

  const [selectedItem, setSelectedItem] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [paxScreenPaymentStatus, setPaxScreenPaymentStatus] = useState([]);
  const [paymentSupressed, setPaymentSupressed] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [locationOpen, setLocationOpen] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('date'));
  const [createdStartDate, setCreatedStartDate] = useState('');
  const [createdEndDate, setCreatedEndDate] = useState('');

  const [filterOption, setFilterOption] = useState({
    appointmentTypes,
    appointmentStatuses,
    startDate,
    endDate
  });

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isHiddenAppointmentTypes = [
    'Clinic Assistant',
    'MHS',
    'LabAdmin'
  ].includes(userInfo.roleName);

  const [sortOption, setSortOption] = useState({});

  useEffect(() => {
    globalDispatcher.getListLocations();
    authDispatcher.getUserPasscode();

    return () => {
      teleAppoinmentDispatcher.resetData();
      allUsersDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    // TeleDoc Appoinment

    const roleType = 'Doctor';
    userInfo.roleName !== 'Doctor' &&
      appointmentManagementDispatcher.getAvailableUser(roleType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.roleName]);

  useEffect(() => {
    if (isEmpty(startDate)) {
      setFilterOption({
        ...filterOption,
        startDate,
        endDate: startDate
      });
    }
    // eslint-disable-next-line
  }, [startDate]);

  const onShowModal = type => {
    setAnchorEl(null);
  };

  const onResetFilterOps = () => {
    setPaymentSupressed([]);
    setPaxScreenPaymentStatus([]);
    setAppointmentTypes([]);
    setAppointmentStatuses([]);
    setLocationFilter([]);
    setStartDate('');
    setEndDate('');
    setCreatedStartDate('');
    setCreatedEndDate('');
    setFilterOption({
      appointmentTypes: [],
      isFreePayment: null
    });
  };

  const onFilterOptionClear = (state, setState, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [state]: defaultValue
    });
    setState(defaultValue);
  };

  const onFilterOptionChange = (state, setState) => e => {
    setFilterOption({
      ...filterOption,
      [state]: e.target.value
    });
    setState(e.target.value);
  };

  const onCancelAppointment = () => {
    if (isEmpty(selectedItem)) return;

    if (userId) {
      // Appointment of Patient
      const { appointmentTypes } = selectedItem;
      let isTeleDocAppointment = AppointmentType.map(i => i.value).some(i =>
        appointmentTypes.includes(i)
      );

      if (isTeleDocAppointment) {
        // TeleConsult Appointment
        appointmentManagementDispatcher.cancelAppointment(
          selectedItem.doctorAppointmentId,
          () => {
            customToast('success', 'Cancel Appointment Success');
            allUsersDispatcher.getAllUsersAppointments(
              searchKey,
              paging,
              filterOption,
              {},
              userId
            );
            setShowCancelModal(false);
          }
        );
      } else {
        // Health Screening Appointment
        teleAppoinmentDispatcher.cancelTeleAppointment(
          selectedItem.paxScreeningId,
          () => {
            customToast('success', 'Cancel Appointment Success');
            allUsersDispatcher.getAllUsersAppointments(
              searchKey,
              paging,
              filterOption,
              {},
              userId
            );
            setShowCancelModal(false);
          }
        );
      }
    } else {
      //All Health Screening Appointment
      teleAppoinmentDispatcher.cancelTeleAppointment(
        selectedItem.paxScreeningId,
        () => {
          customToast('success', 'Cancel Appointment Success');
          teleAppoinmentDispatcher.getData(searchKey, paging, filterOption);
          setShowCancelModal(false);
        }
      );
    }
  };

  const onCreateAppointment = () => {
    // setShowModal(true);
    // setTypeModal(TYPE_MODAL.Create);
    history.push({
      pathname: `/${urlLabel.createAppointment}`
    });
  };

  const onRevertAppointment = () => {
    if (isEmpty(selectedItem)) return;
    teleAppoinmentDispatcher.revertAppoinmentToUpcoming(
      selectedItem.paxScreeningId,
      () => {
        teleAppoinmentDispatcher.getData(searchKey, paging, filterOption);
        setShowRevertModal(false);
      }
    );
  };

  const onEditTeleConsultAppointment = (type, data) => {
    let isTeleDocAppointment = AppointmentType.map(i => i.value).some(
      i => data.appointmentType === i
    );
    if (isTeleDocAppointment)
      appointmentManagementDispatcher.editDoctorToAppointment(
        data.id,
        {
          appointmentType: data.appointmentType,
          appointmentDate: data.appointmentDate
        },
        () => {
          allUsersDispatcher.getAllUsersAppointments(
            searchKey,
            paging,
            filterOption,
            {},
            userId
          );
          setShowModal(false);
        }
      );
  };

  const renderDateTime = row => {
    let startDateFormat, startTimeFormat;
    const { startDate } = row;

    if (!startDate) return null;

    startDateFormat = moment(startDate).format('DD-MM-YYYY');
    startTimeFormat = moment(startDate).format('LT');
    return `${startDateFormat}, ${startTimeFormat}`;
  };

  const renderListAction = (
    status,
    appointmentTypes = [],
    paymentStatus,
    startDate
  ) => {
    if (!status) return [];

    let listActions = [];

    let isTeleDocAppointment = AppointmentType.map(i => i.value).some(i =>
      appointmentTypes.includes(i)
    );
    if (isTeleDocAppointment) {
      listActions = [
        {
          key: 'view',
          icon: VisibilityRounded,
          label: 'View Details',
          onClick: () => onShowModal(TYPE_MODAL.View)
        }
      ];
      return listActions;
    }
    const patientInformationRegistration = () => {
      if (isEmpty(selectedItem)) return;

      const {
        paxScreeningId,
        status,
        healthScreeningId,
        patientId,
        totalCost,
        isFollowUpAppointment
      } = selectedItem;

      history.push({
        pathname: `/${urlLabel.checkInPatientInfo}`,
        state: {
          paxScreeningId,
          status,
          healthScreeningId,
          patientId,
          totalCost,
          isFollowUpAppointment
        }
      });
    };

    const followUpAppointment = () => {
      if (isEmpty(selectedItem)) return;

      history.push({
        pathname: `/${urlLabel.createAppointment}`,
        state: {
          isFollowUp: true,
          paxProps: selectedItem
        }
      });
    };

    switch (status) {
      case STATUSES_APPOINTMENT.DEFAULTED:
        listActions = listActions.concat([
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            }
          }
        ]);
        break;
      case STATUSES_APPOINTMENT.CHECK_IN:
      case STATUSES_APPOINTMENT.IN_PROGRESS:
      case STATUSES_APPOINTMENT.COMPLETED:
        listActions = listActions.concat([
          {
            key: 'view-registration',
            icon: Visibility,
            label: 'View Registration Information',
            onClick: patientInformationRegistration
          }
        ]);
        break;
      case STATUSES_APPOINTMENT.UPCOMING:
        var isCurrentDate = moment(startDate).isSame(new Date(), 'day');
        if (isCurrentDate) {
          listActions = listActions.concat([
            {
              key: 'check-in',
              icon: Check,
              label: 'Check-in location',
              onClick: patientInformationRegistration
            }
          ]);
        }
        listActions = listActions.concat([
          {
            key: 'cancel',
            icon: Cancel,
            label: 'Cancel',
            onClick: () => {
              setAnchorEl(null);
              setShowCancelModal(true);
            }
          },
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            }
          }
        ]);
        break;

      case STATUSES_APPOINTMENT.CHECKIN_LOCATION:
        listActions = listActions.concat([
          {
            key: 'check-in',
            icon: Check,
            label: 'Registration station',
            onClick: patientInformationRegistration
          },
          {
            key: 'edit',
            icon: Edit,
            label: 'Edit',
            onClick: () => {
              setShowModalEdit(true);
              setAnchorEl(null);
            }
          }
        ]);
        break;

      default:
        break;
    }

    listActions = listActions.concat({
      key: 'detail',
      icon: Info,
      label: 'View Detail',
      onClick: () => {
        setIsViewDetail(true);
        setShowModalEdit(true);
        setAnchorEl(null);
      }
    });

    if (
      [
        STATUSES_APPOINTMENT.IN_PROGRESS,
        STATUSES_APPOINTMENT.COMPLETED
      ].includes(status)
    ) {
      // listActions = listActions.concat({
      //   key: 'download',
      //   icon: GetApp,
      //   label: 'Download Report',
      //   onClick: () => {
      //     teleAppoinmentDispatcher.downloadReportByStatus(
      //       selectedItem.paxScreeningId,
      //       selectedItem.shortId
      //     );
      //     setAnchorEl(null);
      //   }
      // });

      listActions.push(
        {
          key: 'export',
          icon: GetApp,
          label: 'Export PDF',
          onClick: () => {
            const hasIRReport = selectedItem.hasIRReport;
            const newPaxScreeningId = selectedItem.paxScreeningId;
            setExporting(true);
            if (hasIRReport) {
              teleAppoinmentDispatcher.downloadIRReport(newPaxScreeningId);
              setExporting(false);
            } else {
              customToast('error', `You don't have any IR report to download`);
              setExporting(false);
            }

            setAnchorEl(null);
          }
        },
        {
          key: 're-generate',
          icon: Cached,
          label: 'Regenerate PDF',
          onClick: () => {
            const newPaxScreeningId = selectedItem.paxScreeningId;
            setExporting(true);
            teleAppoinmentDispatcher.regenerateIRReport(newPaxScreeningId);
            setExporting(false);
            setAnchorEl(null);
          }
        }
      );
    }

    if (
      paymentStatus &&
      paymentStatus !== STATUSES_APPOINTMENT.READY_TO_PAY &&
      ![
        STATUSES_APPOINTMENT.CANCELLED,
        STATUSES_APPOINTMENT.DEFAULTED,
        STATUSES_APPOINTMENT.UPCOMING,
        STATUSES_APPOINTMENT.CHECKIN_LOCATION
      ].includes(status) &&
      !selectedItem.isFollowUpAppointment
    ) {
      listActions.push({
        key: 'follow-up',
        icon: LibraryAddCheck,
        label: 'Follow-up Appointment',
        onClick: followUpAppointment
      });
    }

    if (
      [
        STATUSES_APPOINTMENT.IN_PROGRESS,
        STATUSES_APPOINTMENT.CHECK_IN,
        STATUSES_APPOINTMENT.CHECKIN_LOCATION
      ].includes(status) &&
      !selectedItem.isFollowUpAppointment
    ) {
      listActions = listActions.concat({
        key: 'revert',
        icon: Autorenew,
        label: 'Revert To Upcoming',
        onClick: () => {
          setAnchorEl(null);
          setShowRevertModal(true);
        }
      });
    }
    return listActions;
  };

  const renderListActionAllUsers = selectedItem => {
    if (!selectedItem) return [];
    const {
      status,
      paxScreenPaymentStatus: paymentStatus,
      appointmentTypes
    } = selectedItem;

    let isTeleDocAppointment = AppointmentType.map(i => i.value).some(i =>
      appointmentTypes.includes(i)
    );

    let listActions = [];

    const followUpAppointment = () => {
      if (isEmpty(selectedItem)) return;

      history.push({
        pathname: `/${urlLabel.createAppointment}`,
        state: {
          isFollowUp: true,
          paxProps: selectedItem,
          userId
        }
      });
    };

    if (isTeleDocAppointment) {
      listActions = [
        {
          key: 'edit',
          icon: Edit,
          label: 'Edit',
          disabled: !(
            ![
              AppointmentStatus.Cancelled,
              AppointmentStatus.Rejected,
              AppointmentStatus.Completed,
              'Defaulted'
            ].includes(selectedItem.status) &&
            !ROLES_NAMES_SPECIAL.includes(userInfo.roleName)
          ),
          onClick: () => {
            setSelectedItem(generateTeleConsultData(selectedItem));
            setShowModal(true);
            setAnchorEl(null);
          }
        },
        {
          key: 'cancel',
          icon: Delete,
          label: 'Cancel',
          disabled: !(
            ![
              AppointmentStatus.Cancelled,
              AppointmentStatus.Rejected,
              AppointmentStatus.Completed,
              'Defaulted'
            ].includes(selectedItem.status) &&
            !ROLES_NAMES_SPECIAL.includes(userInfo.roleName)
          ),
          onClick: () => {
            setSelectedItem(selectedItem);
            setShowCancelModal(true);
            setAnchorEl(null);
          }
        }
      ];
    } else {
      listActions = [
        {
          key: 'edit',
          icon: Edit,
          label: 'Edit',
          disabled: ![
            STATUSES_APPOINTMENT.CHECKIN_LOCATION,
            STATUSES_APPOINTMENT.DEFAULTED,
            STATUSES_APPOINTMENT.UPCOMING
          ].includes(status),
          onClick: () => {
            setSelectedItem(selectedItem);
            setShowModalEdit(true);
            setAnchorEl(null);
          }
        },
        {
          key: 'follow-up',
          icon: LibraryAddCheck,
          label: 'Follow-up Appointment',
          disabled: !(
            paymentStatus &&
            paymentStatus !== STATUSES_APPOINTMENT.READY_TO_PAY &&
            ![
              STATUSES_APPOINTMENT.CANCELLED,
              STATUSES_APPOINTMENT.DEFAULTED,
              STATUSES_APPOINTMENT.UPCOMING,
              STATUSES_APPOINTMENT.CHECKIN_LOCATION
            ].includes(status) &&
            !selectedItem.isFollowUpAppointment
          ),
          onClick: followUpAppointment
        },
        {
          key: 'cancel',
          icon: Delete,
          label: 'Cancel',
          disabled: status !== STATUSES_APPOINTMENT.UPCOMING,
          onClick: () => {
            setSelectedItem(selectedItem);
            setShowCancelModal(true);
            setAnchorEl(null);
          }
        }
      ];
    }

    return listActions;
  };

  const generateTeleConsultData = selectedItem => {
    if (!selectedItem) return {};

    const {
      doctorAppointmentId,
      status,
      appointmentTypes,
      startDate,
      fullName,
      doctorId,
      doctorFirstName,
      doctorLastName,
      patientId,
      nric,
      shortId
    } = selectedItem;
    const doctorFullName =
      doctorFirstName && doctorLastName
        ? `${selectedItem.doctorFirstName} ${selectedItem.doctorLastName}`
        : null;
    const formatData = {
      id: doctorAppointmentId,
      appointmentStatus: status,
      appointmentType: appointmentTypes[0],
      appointmentDate: startDate,
      doctorId,
      doctorFullName,
      patientFullName: fullName,
      patientId,
      patientNRIC: nric,
      patientShortId: shortId
    };
    return formatData;
  };

  const exportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    let params = {
      ...sortOption,
      appointmentTypes,
      search: searchKey,
      appointmentStatuses,
      startDate,
      endDate,
      userId,
      paxScreenPaymentStatus,
      locationIds: locationFilter,
      createdStartDate,
      createdEndDate
    };

    if (!isEmpty(paymentSupressed)) {
      params = { ...params, isFreePayment: !isEmpty(paymentSupressed) };
    }
    teleAppoinmentDispatcher.getExportExcel(params);
  };

  const copyLinkClipboard = async text => {
    if (!navigator?.clipboard) {
      customToast('error', 'Clipboard not supported!');
      return;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      customToast('success', 'Copy successful!');
    } catch (error) {
      customToast('error', 'Copy failed!');
    }
  };
  const listActions = renderListAction(
    selectedItem?.status,
    selectedItem?.appointmentTypes,
    selectedItem?.paxScreenPaymentStatus,
    selectedItem?.startDate
  );

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => {
    const allCols = {
      [appointmentColNames.user]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.user}
          label="User"
          stateValue="user"
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.registrationStatus]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.registrationStatus}
          label="Registration Status"
          stateValue="registrationStatus"
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.paymentSuppression]: (
        <TableCellFiltedWrapper
          label="Free Payment"
          stateValue="paymentSuppression"
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.NRIC]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.NRIC}
          label="NRIC"
          stateValue="nric"
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.dateTime]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.dateTime}
          label="Date & Time"
          stateValue="dateTime"
          sortBy="Date"
          sortOption={sortOption}
          onSortChange={() => {
            let newSortOption = {
              sortBy: 'BookedApptDate',
              orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc'
            };
            setSortOption(newSortOption);
          }}
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.location]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.location}
          label="Location"
          stateValue="location"
          style={{ width: 200 }}
        />
      ),
      [appointmentColNames.status]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.status}
          label="Status"
          stateValue="status"
          style={{ width: 160 }}
        />
      ),
      [appointmentColNames.appointmentTypes]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.appointmentTypes}
          label="Appointment Types"
          stateValue="appointmentTypes"
          style={{ width: 160 }}
        />
      ),
      [appointmentColNames.totalCost]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.totalCost}
          label="Total Cost"
          stateValue="totalCost"
          style={{ width: 160 }}
        />
      ),
      [appointmentColNames.paymentStatus]: (
        <TableCellFiltedWrapper
          key={appointmentColNames.paymentStatus}
          label="Payment Status"
          stateValue="paymentStatus"
          style={{ width: 160 }}
        />
      )
    };

    const arrCols = !userId ? defaultAppointmentCols : headerCols;

    return (
      <TableHead>
        <TableRow>
          {arrCols.map(colId => allCols[colId])}
          <TableCellSticky align="right">
            {!hideExportExcel && (
              <IconButton
                aria-label="delete"
                style={{ padding: 0 }}
                onClick={exportExcel}
              >
                <GetApp />
              </IconButton>
            )}
          </TableCellSticky>
        </TableRow>
      </TableHead>
    );
  };

  const renderRegistrationStatus = row => (
    <div style={{ display: 'flex' }}>
      <Tooltip title="Payment Status">
        {row.paxScreenPaymentStatus === 'Paid' ? (
          <div className={classes.wrapperIcon}>
            <CheckedIcon className={classes.checkedIcon} />
            <PaymentActive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <PaymentInactive />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Consent Form">
        {row.status === STATUSES_APPOINTMENT.UPCOMING ||
        row.consentInfo.totalCompleted !== row.consentInfo.total ? (
          <div className={classes.wrapperIcon}>
            <ConsentFormInactive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <CheckedIcon className={classes.checkedIcon} />
            <ConsentFormActive />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Survey">
        {row.surveyInfo.totalCompleted === row.surveyInfo.total ? (
          <div className={classes.wrapperIcon}>
            <CheckedIcon className={classes.checkedIcon} />
            <SurveyActive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <SurveyInative />
          </div>
        )}
      </Tooltip>
      <Tooltip title="Physical Examination">
        {row.patientPEInfo.totalCompleted !== row.patientPEInfo.total ? (
          <div className={classes.wrapperIcon}>
            <PhysicalExamInactive />
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <CheckedIcon className={classes.checkedIcon} />
            <PhysicalExamActive />
          </div>
        )}
      </Tooltip>
    </div>
  );

  const TablCells = row => {
    const allCells = {
      [appointmentColNames.user]: (
        <FiltedBodyCell hidden={hiddenRows.user} key={appointmentColNames.user}>
          <CustomTooltip maxLength={20} content={row.fullName} />
          <Typography style={{ color: '#8F9BA9' }}>{row.shortId}</Typography>
        </FiltedBodyCell>
      ),
      [appointmentColNames.registrationStatus]: (
        <FiltedBodyCell
          hidden={hiddenRows.registrationStatus}
          key={appointmentColNames.registrationStatus}
        >
          {row.consentInfo &&
            row.surveyInfo &&
            row.paxScreenPaymentStatus &&
            renderRegistrationStatus(row)}
        </FiltedBodyCell>
      ),
      [appointmentColNames.paymentSuppression]: (
        <FiltedBodyCell hidden={hiddenRows.paymentSuppression}>
          <Checkbox
            name="paymentSuppression"
            checked={row.isPaxscreenSuppression}
            disabled
          />
        </FiltedBodyCell>
      ),

      [appointmentColNames.NRIC]: (
        <FiltedBodyCell hidden={hiddenRows.nric} key={appointmentColNames.NRIC}>
          {row.nric}
        </FiltedBodyCell>
      ),
      [appointmentColNames.dateTime]: (
        <FiltedBodyCell
          hidden={hiddenRows.dateTime}
          key={appointmentColNames.dateTime}
        >
          {renderDateTime(row)}
        </FiltedBodyCell>
      ),
      [appointmentColNames.location]: (
        <FiltedBodyCell
          hidden={hiddenRows.location}
          key={appointmentColNames.location}
        >
          <CustomTooltip maxLength={40} content={row.location} />
        </FiltedBodyCell>
      ),
      [appointmentColNames.status]: (
        <FiltedBodyCell
          hidden={hiddenRows.status}
          key={appointmentColNames.status}
        >
          <StatusComponent status={row.status} />
        </FiltedBodyCell>
      ),
      [appointmentColNames.appointmentTypes]: (
        <FiltedBodyCell
          hidden={hiddenRows.appointmentTypes}
          key={appointmentColNames.appointmentTypes}
        >
          {!isEmpty(row.appointmentTypes)
            ? row.appointmentTypes
                .map((appType, index) => AllUsersAppointmentType[appType])
                .join(', ')
            : null}
        </FiltedBodyCell>
      ),
      [appointmentColNames.totalCost]: (
        <FiltedBodyCell
          hidden={hiddenRows.totalCost}
          key={appointmentColNames.totalCost}
        >
          {formatTotalPrice(row.totalCost, '$')}
        </FiltedBodyCell>
      ),
      [appointmentColNames.paymentStatus]: (
        <FiltedBodyCell
          hidden={hiddenRows.paymentStatus}
          key={appointmentColNames.paymentStatus}
        >
          {row.paxScreenPaymentStatus && (
            <Tooltip title="Payment Status">
              {row.paxScreenPaymentStatus === 'Paid' ? (
                <div className={classes.wrapperIcon}>
                  <CheckedIcon className={classes.checkedIcon} />
                  <PaymentActive />
                </div>
              ) : (
                <div className={classes.wrapperIcon}>
                  <PaymentInactive />
                </div>
              )}
            </Tooltip>
          )}
        </FiltedBodyCell>
      )
    };

    const arrCells = !userId ? defaultAppointmentCols : headerCols;

    return arrCells.map(cellId => allCells[cellId]);
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuAction listActions={listActions} />
      </Menu>
      <TableContainer
        fullWidthSearch
        toolbar={showToolbar}
        title="Appointments"
        defaultSearchInput={false}
        paging={!userId ? paging : pagingAllUsers}
        data={!userId ? data : dataAllUsers}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        sortOption={sortOption}
        query={(...params) => {
          !userId
            ? teleAppoinmentDispatcher.getData(...params, userId)
            : allUsersDispatcher.getAllUsersAppointments(...params, userId);
        }}
        customButtons={
          <TextField
            value={userPasscode}
            style={{ pointerEvents: 'none', width: 200 }}
            InputProps={{
              startAdornment: <InputAdornment>Passcode: </InputAdornment>,
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    style={{ pointerEvents: 'auto' }}
                    color="primary"
                    onClick={() => copyLinkClipboard(userPasscode)}
                  >
                    <FileCopyOutlined />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        }
        ToolbarComponent={
          <GroupSearch
            {...{
              userId,
              setSearchKey,
              searchKey,
              appointmentTypes,
              setAppointmentTypes,
              filterOption,
              onFilterOptionClear,
              onFilterOptionChange,
              setFilterOption,
              startDate,
              setStartDate,
              endDate,
              setEndDate,
              onResetFilterOps,
              listLocations,
              locationOpen,
              setLocationOpen,
              locationFilter,
              setLocationFilter,
              appointmentStatuses,
              setAppointmentStatuses,
              paxScreenPaymentStatus,
              setPaxScreenPaymentStatus,
              onCreateAppointment,
              paymentSupressed,
              setPaymentSupressed,
              createdStartDate,
              setCreatedStartDate,
              createdEndDate,
              setCreatedEndDate
            }}
          />
        }
        renderRow={(row, i) => {
          return (
            <>
              {TablCells(row)}
              {userId ? (
                <TableCellSticky align="right" style={{ width: 200 }}>
                  {renderListActionAllUsers(row).length !== 0 &&
                    renderListActionAllUsers(row).map(action => {
                      const Icon = action.icon;

                      return (
                        <Tooltip title={action.label}>
                          <IconButton
                            onClick={action.onClick}
                            disabled={action.disabled}
                          >
                            <Icon />
                          </IconButton>
                        </Tooltip>
                      );
                    })}
                </TableCellSticky>
              ) : (
                <TableCellSticky align="right">
                  {renderListAction(
                    row.status,
                    row.appointmentTypes,
                    null,
                    row.startDate
                  ).length !== 0 && (
                    <IconButton
                      onClick={e => {
                        setIsViewDetail(false);
                        setAnchorEl(e.currentTarget);
                        setSelectedItem(row);
                      }}
                    >
                      <MoreVertRounded />
                    </IconButton>
                  )}
                </TableCellSticky>
              )}
            </>
          );
        }}
      />
      <Dialog
        open={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        maxWidth="md"
        fullWidth
        scroll="body"
        disableEnforceFocus
      >
        <EditAppointment
          isViewDetail={isViewDetail}
          onClose={() => setShowModalEdit(false)}
          selectedItem={selectedItem}
          cbGetData={() =>
            !userId
              ? teleAppoinmentDispatcher.getData(
                  searchKey,
                  paging,
                  filterOption
                )
              : allUsersDispatcher.getAllUsersAppointments(
                  searchKey,
                  paging,
                  filterOption,
                  {},
                  userId
                )
          }
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={selectedItem}
          onClose={() => setDeleteModal(false)}
          onDelete={() => {}}
          modalName="Package"
        />
      )}
      {showCancelModal && (
        <DeleteModal
          showModal={showCancelModal}
          selectedItem={selectedItem}
          onClose={() => setShowCancelModal(false)}
          onDelete={onCancelAppointment}
          title={'Do you want to cancel this appointment ?'}
          content={'Click "Continue" to cancel'}
          preText="The transaction will be cancelled and refunded to the account"
          Icon={CancelOutlined}
        />
      )}
      {showRevertModal && (
        <DeleteModal
          showModal={showRevertModal}
          selectedItem={selectedItem}
          onClose={() => setShowRevertModal(false)}
          onDelete={onRevertAppointment}
          title={'Do you want to revert this appointment ?'}
          content={'Click "Continue" to revert'}
          preText="This appointment will be reverted to Upcoming status"
          Icon={Autorenew}
        />
      )}

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="md"
        fullWidth
        scroll="body"
        disableEnforceFocus
      >
        <AppointmentForm
          onClose={() => {
            setShowModal(false);
          }}
          selectedItem={selectedItem}
          typeModal="Edit"
          listAvailableUsers={listAvailableUsers}
          onSubmit={onEditTeleConsultAppointment}
          isDoctor={userInfo.roleName === 'Doctor'}
          isSwabber={userInfo.roleName === 'Swabber'}
          isHiddenAppointmentTypes={isHiddenAppointmentTypes}
        />
      </Dialog>

      <Dialog open={exporting}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Exporting PDF
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* {paxScreening && (
        <PdfExport paxScreening={paxScreening} onDone={onExportPdfDone} />
      )} */}
    </>
  );
};

export default TeleAppointmentManagement;
