import React from 'react';
import { DetailWrapped, WrapperButtonUpload } from './styled';
import {
  Grid,
  TextField,
  makeStyles,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
// import {
//   USER_GENDER,
//   FORMAT_DD_MM_YYYY,
//   ID_TYPE,
//   EditableHsStatus
// } from '../../constants';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
// import ControlDatepicker from '@/module/common/component/ControlDatepicker';
// import { InputAddressEnhance } from '@/module/common/component';
// import PhoneInput from '@/components/PhoneInput';
import { get, cloneDeep, set } from 'lodash';
// import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { UploadSingle } from '@/module/common/component';
import { AddImageOutlineIcon } from '@/assets/svg';
import { WrappedItem } from '../MedicalHistory/CommonStyles';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    marginRight: 8
  },
  wrapperItem: {
    width: '100%',
    backgroundColor: '#F6F7FA',
    padding: '10px 20px 0 20px'
  },
  rowItem: {
    margin: 0,
    overflowY: 'auto',
    paddingBottom: 10
  },
  note: {
    overflowWrap: 'anywhere'
  }
});

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8
  }
})(InputLabel);

const CorporateInformation = ({
  personalInfo,
  setPersonalInfo,
  errors,
  isEdit
}) => {
  const classes = useStyles();

  const handleChange = key => e => {
    let value = get(e, 'target.value');

    setPersonalInfo({
      ...personalInfo,
      [key]: value
    });
  };

  const handleChangeIdentity = (data, field) => {
    const payload = cloneDeep(personalInfo);
    set(payload, field, data);
    setPersonalInfo(payload);
  };

  return (
    <DetailWrapped>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            {isEdit ? (
              <TextField
                fullWidth
                label="Corporate Plan"
                value={personalInfo.corporateName || ''}
                onChange={handleChange('corporateName')}
                disabled={!isEdit}
                error={errors.corporateName}
                helperText={errors.corporateName}
              />
            ) : (
              <div className={classes.wrapperItem}>
                <div className={classes.rowItem}>
                  <span className={classes.title}>Corporate Plan:</span>
                  <span>{personalInfo.corporateName}</span>
                </div>
              </div>
            )}
          </Grid>
          <Grid container spacing={4} style={{ margin: 0 }}>
            <Grid item xs={6}>
              <CustomInputLabel disabled={!isEdit}>
                Corporate Card Front
              </CustomInputLabel>
              <UploadSingle
                typeUpload="normal"
                accept=".jpg,.png,.jpeg"
                onChange={data =>
                  handleChangeIdentity(data, 'corporateFrontPhoto')
                }
                imageUrlSelected={get(personalInfo, 'corporateFrontPhoto')}
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>
                    <span className="text">Add Corporate Picture</span>
                  </WrapperButtonUpload>
                }
                disabled={!isEdit}
              />
              {errors.corporateFrontPhoto && (
                <Typography color="error">
                  {errors.corporateFrontPhoto}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputLabel disabled={!isEdit}>
                Corporate Card Back
              </CustomInputLabel>
              <UploadSingle
                typeUpload="normal"
                accept=".jpg,.png,.jpeg"
                onChange={data =>
                  handleChangeIdentity(data, 'corporateBackPhoto')
                }
                imageUrlSelected={get(personalInfo, 'corporateBackPhoto')}
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>{' '}
                    <span className="text">Add Corporate Picture</span>
                  </WrapperButtonUpload>
                }
                disabled={!isEdit}
              />
              {errors.corporateBackPhoto && (
                <Typography color="error">
                  {errors.corporateBackPhoto}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {isEdit ? (
              <TextField
                multiline
                fullWidth
                label="Note"
                value={personalInfo.corporateNote || ''}
                onChange={handleChange('corporateNote')}
                rowsMax={5}
              />
            ) : (
              <WrappedItem>
                <div style={{ display: 'inline-flex' }}>
                  <span className={classes.title}>Note:</span>
                  <span className={classes.note}>
                    {personalInfo.corporateNote}
                  </span>
                </div>
              </WrappedItem>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </DetailWrapped>
  );
};

export default CorporateInformation;
