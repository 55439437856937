import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import clsx from 'clsx';

import allUsersDispatcher from '../../action';
import {
  ButtonEnhance,
  StatusWrapper
} from '@/module/common/componentUI/commonStyleComponents';
import { PaperWrapped, HeaderWrapped } from './styled';
import PersonalInformation from './PersonalInformation';
import { Box, makeStyles, Typography } from '@material-ui/core';
import validateData from '@/helpers/validationHelpers/validationSchema';
import TabsUI from '@/module/common/componentUI/TabsUI';
import { useSelector } from 'react-redux';
import LoadingButton from '@/components/LoadingButton';
import { urlLabel } from '@/enum/PermissionEnum';

import breadcrumbDispatcher from '@/redux/breadcrumb/actions';

import CorporateInformation from './CorporateInformation';
import UserAppointments from '../UserAppointments';
import FamilyGroup from '../FamilyGroup';
import Records from '../Records';
import PhysicalInformation from './PhysicalInformation';
import MedicalHistory from '../MedicalHistory';

import {
  INIT_DATA_PE_DOCTOR,
  INIT_DATA_PE_PATIENT,
  ROLES_PHYSICALEXAMINATION,
  SURVEY_ANSWER_TYPE
} from '../../constants';
import { isJsonString } from '@/helpers';
import globalDispatcher from '@/redux/global/actions';
import customToast from '@/new-components/CustomNotification';

const useStyles = makeStyles({
  alertWrapper: {
    padding: '5px 10px',
    margin: '0px 5px',
    width: 'fit-content',
    verticalAlign: 'middle',

    fontSize: 12,
    borderRadius: 50,
    border: '1px solid #F6133D',
    color: '#F6133D',
    textTransform: 'uppercase'
  },
  statusWrapper: {
    borderRadius: 50,
    verticalAlign: 'middle'
  },
  titleWrapper: {
    marginRight: 20
  }
});

const UserDetail = () => {
  const { loading } = useSelector(state => state.allUsers);
  const roleType = useSelector(state => state.auth.roleType);

  const [userFullName, setUserFullName] = useState('');
  const [alertValue, setAlertValue] = useState('');

  const [personalInfo, setPersonalInfo] = useState({
    countryCode: 64,
    identityType: 'NRIC'
  });
  const [errors, setErrors] = useState({});

  const [currentTab, setCurrentTab] = useState(0);
  const [isDoctorSide, setIsDoctorSide] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [userIdState, setUserIdState] = useState('');

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const userIdParam = searchParams.get('user');

  const userID = useMemo(
    () => userIdParam || userIdState || get(location, 'state.id'),
    [location, userIdState, userIdParam]
  );

  const [currentSelectValue, setCurrentSelectValue] = useState({});

  const physicalExamInfoDoctor = INIT_DATA_PE_DOCTOR.sections;
  const physicalExamInfoPatient = INIT_DATA_PE_PATIENT;
  const [dataPEDoctor, setDataPEDoctor] = useState({
    doctorJsonData: null,
    doctorSubmittedDate: null,
    paxScreeningId: null
  });
  const [dataPEPatient, setDataPEPatient] = useState({
    patientJsonData: null,
    patientSubmittedDate: null,
    paxScreeningId: null
  });
  const [physicalExamInfo, setPhysicalExamInfo] = useState([]);
  const [medicalInfo, setMedicalInfo] = useState([]);

  const customRoutesRef = useRef([]);
  const [lstIdentityUrl, setLstIdentityUrl] = useState([]);

  useEffect(() => {
    if (!userID) return history.goBack();

    fetchDataUserDetail();
    setCurrentTab(0);
    if (userID) {
      globalDispatcher.getIdentityImages(userID, data => {
        setLstIdentityUrl(data);
      });
    }
  }, [userID]);

  const resetDoctorJsonData = dataDoctor => {
    const resetData = dataDoctor.map(section => ({
      ...section,
      options: section.options.map(opt => ({
        ...opt,
        valueSelected: null,
        list: opt.list.map(ans =>
          ans.hasOwnProperty('detail') ? { ...ans, detail: null } : ans
        )
      }))
    }));
    return resetData;
  };

  const resetPatientJsonData = dataClient => {
    const resetData = dataClient.map(section => ({
      ...section,
      questions: section.questions.map(ques => ({
        ...ques,
        answers: ques.answers.map(ans => ({
          ...ans,
          isSelected: false,
          patientNote: ''
        }))
      }))
    }));
    return resetData;
  };

  useEffect(() => {
    // Update breadcrumbs
    if (!userFullName) return;

    if (personalInfo?.isParent) {
      saveCustomBreadcrumb();
    } else {
      updateChildBreadcrumb();
    }
  }, [userFullName]);

  const saveCustomBreadcrumb = passedRoutes => {
    const customRoutes = passedRoutes
      ? passedRoutes
      : [
          {
            text: 'All Users',
            router: '/all-users'
          },
          {
            text: userFullName,
            router: `/${urlLabel.editUserDetail}?user=${personalInfo.id}`
          }
        ];

    customRoutesRef.current = customRoutes;
    breadcrumbDispatcher.addCustomBreadcrumb({
      routes: customRoutes,
      asPath: location.pathname
    });
  };

  const updateChildBreadcrumb = () => {
    const length = customRoutesRef.current.length;

    if (!length) {
      // View dependent user directly, not from parent user
      saveCustomBreadcrumb();
    } else {
      // View dependent user from parent user
      const newBreadcrumb = customRoutesRef.current.map((item, index) => {
        // Update the last item
        if (index === length - 1) {
          item.text = userFullName;
        }

        return item;
      });

      saveCustomBreadcrumb(newBreadcrumb);
    }
  };

  const fetchDataPhysicalHistories = async (id, refreshAfterEdit = false) => {
    allUsersDispatcher.getPhysicalHistories(id, (data = []) => {
      const newData = data.map(d => {
        const isCheckDoctor = isJsonString(d.doctorJsonData);
        const isCheckPatient = isJsonString(d.patientJsonData);

        const doctorJsonData = isCheckDoctor
          ? JSON.parse(d.doctorJsonData)
          : resetDoctorJsonData(physicalExamInfoDoctor);
        const patientJsonData = isCheckPatient
          ? JSON.parse(d.patientJsonData)
          : resetPatientJsonData(physicalExamInfoPatient);
        return {
          ...d,
          doctorJsonData,
          patientJsonData
        };
      });
      if (!isEmpty(currentSelectValue) && !refreshAfterEdit) {
        if (
          currentSelectValue.doctorSubmittedDate ||
          currentSelectValue.patientSubmittedDate
        ) {
          const newCurrentSelect = newData.find(
            item => item.id === currentSelectValue.id
          );
          setCurrentSelectValue(newCurrentSelect);
        } else {
          if (isDoctorSide) {
            setCurrentSelectValue({
              ...currentSelectValue,
              doctorJsonData: resetDoctorJsonData(
                currentSelectValue.doctorJsonData
              )
            });
          } else {
            setCurrentSelectValue({
              ...currentSelectValue,
              patientJsonData: resetPatientJsonData(
                currentSelectValue.patientJsonData
              )
            });
          }
        }
      } else {
        setCurrentSelectValue(newData[0]);
      }
      if (!isEmpty(newData)) setPhysicalExamInfo(newData);
    });
  };

  const fetchDataUserDetail = () => {
    allUsersDispatcher.getUserDetail(userID, result => {
      if (!isEmpty(result)) {
        setPersonalInfo(result);
        setUserFullName(result.fullName);
        setAlertValue(result.alert);
      }
    });
    globalDispatcher.getIdentityImages(userID, data => {
      setLstIdentityUrl(data);
    });
  };

  const fetchDataMedicalInfo = () => {
    allUsersDispatcher.getMedicalInfoByID(userID, result => {
      if (!isEmpty(result)) {
        let newData = { ...result };
        if (newData.physicalHistory) {
          // Transfer physical examinatioon data to json object
          const patientPEJson = isJsonString(
            newData?.physicalHistory?.patientPEJson
          )
            ? JSON.parse(newData?.physicalHistory?.patientPEJson)
            : [];

          newData.physicalHistory.patientPEJson = patientPEJson;
        }
        setMedicalInfo(newData);
      }
    });
  };

  const onCancel = () => history.goBack();

  const onSavePersonalInfo = async () => {
    try {
      await validateData(
        'personalInformationSchema',
        personalInfo,
        async () => {
          if (!isEmpty(personalInfo.lstIdentityUrl)) {
            try {
              const lstIdentityUrl = await uploadFiles(
                personalInfo.lstIdentityUrl
              );

              allUsersDispatcher.editPersonalInfo(
                personalInfo.id,
                { ...personalInfo, lstIdentityUrl },
                () => {
                  onCancelPersonalInfo();
                }
              );
            } catch (error) {
              console.error('Failed to upload files:', error);
            }
          } else {
            allUsersDispatcher.editPersonalInfo(
              personalInfo.id,
              personalInfo,
              () => onCancelPersonalInfo()
            );
          }

          setErrors({});
        }
      );
    } catch (err) {
      setErrors(err);
    }
  };

  const onCancelPersonalInfo = () => {
    fetchDataUserDetail();
    setIsEdit(false);
    setErrors({});
  };

  const onCancelPhysicalExam = () => {
    if (!personalInfo.id) return;
    fetchDataPhysicalHistories(personalInfo.id);
    setIsEdit(false);
    setErrors({});
  };
  const uploadFileAndGetURL = async file => {
    const timeStamp = new Date().getTime();
    const fileName = `${timeStamp}${file.name}`;
    const formdata = new FormData();
    formdata.append('file', file, fileName);

    return new Promise((resolve, reject) => {
      globalDispatcher.uploadIdentityImage(formdata, result => {
        if (result) {
          resolve(result);
        } else {
          reject('Upload failed');
        }
      });
    });
  };

  const uploadFiles = async fileList => {
    const uploadPromises = fileList.map(file => {
      if (file?.constructor === File) {
        return uploadFileAndGetURL(file);
      }
      return Promise.resolve(file); // Trả về file nếu nó không phải là một đối tượng File
    });

    return Promise.all(uploadPromises);
  };

  const onCancelMedicalHistory = () => {
    fetchDataMedicalInfo();
    setIsEdit(false);
    setErrors({});
  };

  const validatePhysicalExam = ({ dataDoctor, dataPatient }) => {
    // const dataDoctor = dataPEDoctor.doctorJsonData;
    // const dataPatient = dataPEPatient.patientJsonData;
    // Doctor PE
    let isNoPatientNote = false;
    let isNoDoctortNote = false;
    let isForceSelected = false;
    let isSelectAtLeastOne = false;
    if (!isEmpty(dataDoctor)) {
      dataDoctor.forEach(data => {
        data.options.forEach(option => {
          const noDoctorNote = option.list.some(
            item =>
              item.hasOwnProperty('detail') &&
              item.value === option.valueSelected &&
              isEmpty(item.detail)
          );
          if (noDoctorNote) isNoDoctortNote = true;
        });
      });
    }

    // Client PE
    if (!isEmpty(dataPatient)) {
      dataPatient.forEach(data => {
        data.questions.forEach(question => {
          const newOtionsParent = data.questions.find(
            item => item.id === question.mapParentQuestionId
          );

          const newOtionsChild = data.questions.find(
            item => item.mapParentQuestionId === question.mapParentQuestionId
          );

          const newOtionsChildSingle = data.questions.filter(
            item => item.answerType === SURVEY_ANSWER_TYPE.SingleChoice
          );

          const noPatientNote = question.answers.some(
            answer =>
              answer.isAdditional &&
              answer.isSelected &&
              isEmpty(answer.patientNote)
          );
          if (noPatientNote) isNoPatientNote = true;

          if (newOtionsParent && newOtionsChild) {
            const isParentActive = newOtionsParent?.answers.find(
              item => item.isSelected
            );
            const isChildActive = newOtionsChild.answers.filter(
              answer => answer.isSelected
            );

            if (isParentActive) {
              if (!isEmpty(newOtionsChildSingle)) {
                let isChildActive = [];
                let isChildTotal = [];
                newOtionsChildSingle.forEach(item => {
                  const itemSelected = item.answers.find(it => it.isSelected);
                  isChildTotal = isChildTotal.concat(item.answers);
                  if (itemSelected) isChildActive.push(itemSelected);
                });

                // Need to divide into 2 for single choice select, 1 question have 2 answers (YES and NO)
                if (isChildTotal.length / 2 === isChildActive.length) {
                  isForceSelected = false;
                } else isForceSelected = true;
              }
              if (!isEmpty(isChildActive)) {
                isSelectAtLeastOne = false;
              } else {
                isSelectAtLeastOne = true;
              }
            }
          }
        });
      });
    }

    if (isNoPatientNote)
      setErrors({ ...errors, patientNote: 'The detail is required!' });
    if (isNoDoctortNote)
      setErrors({ ...errors, doctorNote: 'The detail is required!' });
    else if (isSelectAtLeastOne) {
      setErrors({
        ...errors,
        atLeastChooseAnswer: 'You need to choose at least option!'
      });
    }
    if (isForceSelected) {
      setErrors({
        ...errors,
        forceAnswer: 'This question is required!'
      });
    }

    return {
      isNoPatientNote,
      isForceSelected,
      isSelectAtLeastOne,
      isNoDoctortNote
    };
  };
  const onSavePhysicalExam = async () => {
    const {
      isNoPatientNote,
      isForceSelected,
      isSelectAtLeastOne,
      isNoDoctortNote
    } = validatePhysicalExam({
      dataDoctor: dataPEDoctor.doctorJsonData,
      dataPatient: dataPEPatient.patientJsonData
    });
    try {
      if (
        isNoPatientNote ||
        isForceSelected ||
        isSelectAtLeastOne ||
        isNoDoctortNote
      ) {
        customToast(
          'error',
          'Please enter the answer before saving your change!'
        );
      } else {
        if (dataPEDoctor.paxScreeningId) {
          const doctorJsonData = JSON.stringify(dataPEDoctor.doctorJsonData);

          const patientJsonData =
            // Doctor PE submit without client PE
            isDoctorSide && !dataPEPatient.patientSubmittedDate
              ? null
              : JSON.stringify(dataPEPatient.patientJsonData);

          allUsersDispatcher.updateDoctorPE(
            {
              paxScreeningId: dataPEDoctor.paxScreeningId,
              patientJsonData,
              doctorJsonData
            },
            () => {
              fetchDataPhysicalHistories(personalInfo.id, true);
              setIsEdit(false);
              setErrors({});
            }
          );
        }
      }
    } catch (err) {
      setErrors(err);
    }
  };

  const onSaveMedicalHistory = async () => {
    // Transfer physical examinatioon data to string
    if (medicalInfo?.physicalHistory?.patientPEJson) {
      const { isNoPatientNote } = validatePhysicalExam({
        dataPatient: medicalInfo.physicalHistory.patientPEJson
      });

      try {
        if (isNoPatientNote) {
          customToast(
            'error',
            'Please enter the answer before saving your change!'
          );
        } else {
          const patientPEJson = JSON.stringify(
            medicalInfo.physicalHistory.patientPEJson
          );
          medicalInfo.physicalHistory.patientPEJson = patientPEJson;
          allUsersDispatcher.updateMedicalInfo(userID, medicalInfo, () => {
            onCancelMedicalHistory();
          });
        }
      } catch (err) {
        setErrors(err);
      }
    } else {
      allUsersDispatcher.updateMedicalInfo(userID, medicalInfo, () => {
        fetchDataMedicalInfo();
        setIsEdit(false);
        setErrors({});
      });
    }
  };

  const handleSaveChange = key => {
    switch (key) {
      case 'userProfile':
      case 'corporateInformation':
        onSavePersonalInfo();
        break;
      case 'physicalExamination':
        onSavePhysicalExam();
        break;
      case 'medicalInformation':
        onSaveMedicalHistory();
        break;
      default:
        break;
    }
  };

  const handleCancel = key => {
    switch (key) {
      case 'userProfile':
      case 'corporateInformation':
        onCancelPersonalInfo();
        break;
      case 'physicalExamination':
        onCancelPhysicalExam();
        break;
      case 'medicalInformation':
        onCancelMedicalHistory();
        break;
      default:
        break;
    }
  };

  const renderHeader = key => {
    return (
      <div className="personal-btn">
        {isEdit ? (
          <>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={() => handleCancel(key)}
            >
              Cancel
            </ButtonEnhance>
            <LoadingButton
              onClick={() => handleSaveChange(key)}
              showLoader={loading}
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <>
            {!ROLES_PHYSICALEXAMINATION.includes(roleType) &&
            key === 'physicalExamination' ? null : (
              <>
                <ButtonEnhance
                  background="#E9E9E9"
                  color="black"
                  backgroundHover="#ccc"
                  onClick={onCancel}
                >
                  Cancel
                </ButtonEnhance>
                <LoadingButton
                  onClick={() => setIsEdit(true)}
                  showLoader={loading}
                >
                  Edit
                </LoadingButton>
              </>
            )}
          </>
        )}
      </div>
    );
  };
  const handleViewDependantUser = dependantUser => {
    handleUpdateBreadcrumb(dependantUser);
    setUserIdState(dependantUser.id);

    history.push({
      pathname: location.pathname,
      search: 'subUser=' + dependantUser.id
    });
  };

  const handleUpdateBreadcrumb = dependantUser => {
    const newCustomRoutes = [
      ...customRoutesRef.current,
      {
        text: dependantUser.fullName,
        router: `/${urlLabel.editUserDetail}?subUser=${dependantUser.id}`
      }
    ];

    saveCustomBreadcrumb(newCustomRoutes);
  };

  const userDetailTabs = [
    {
      key: 'userProfile',
      label: 'User Profile',
      body: (
        <PersonalInformation
          errors={errors}
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          isEdit={isEdit}
          lstIdentityUrl={lstIdentityUrl}
        />
      ),
      header: renderHeader('userProfile')
    },
    {
      key: 'medicalInformation',
      label: 'Medical Information',
      body: (
        <MedicalHistory
          medicalInfo={medicalInfo}
          setMedicalInfo={setMedicalInfo}
          isEdit={isEdit}
          errors={errors}
          gender={personalInfo.gender}
          fetchData={fetchDataMedicalInfo}
        />
      ),
      header: renderHeader('medicalInformation')
    },
    {
      key: 'corporateInformation',
      label: 'Corporate Information',
      body: (
        <CorporateInformation
          errors={errors}
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          isEdit={isEdit}
        />
      ),
      header: renderHeader('corporateInformation')
    },
    {
      key: 'appointments',
      label: 'Appointments',
      body: <UserAppointments userId={userID} />
    },
    {
      key: 'physicalExamination',
      label: 'Physical Examination',
      body: (
        <PhysicalInformation
          isEdit={isEdit}
          setIsDoctorSide={setIsDoctorSide}
          dataPEDoctor={dataPEDoctor}
          setDataPEDoctor={setDataPEDoctor}
          dataPEPatient={dataPEPatient}
          setDataPEPatient={setDataPEPatient}
          physicalExamInfo={physicalExamInfo}
          currentSelectValue={currentSelectValue}
          setCurrentSelectValue={setCurrentSelectValue}
          errors={errors}
          fetchData={() => fetchDataPhysicalHistories(userID)}
        />
      ),
      header: renderHeader('physicalExamination')
    },
    {
      key: 'records',
      label: 'Records',
      body: (
        <Records
          userId={userID}
          handleViewDependantUser={handleViewDependantUser}
        />
      )
    },
    {
      key: 'familyGroup',
      label: 'Family Group',
      body: (
        <FamilyGroup
          userId={userID}
          handleViewDependantUser={handleViewDependantUser}
        />
      )
    },
    {
      key: 'carePlan',
      label: 'Care Plan',
      body: <div>Coming soon...</div>
    }
  ];
  // Children account ->  Remove 2 tabs "Physical Examination & Family Group"
  const newUserDetailTabs = userDetailTabs.filter(tabs =>
    personalInfo?.isParent
      ? !isEmpty(tabs)
      : !['physicalExamination', 'familyGroup'].includes(tabs.key)
  );

  return (
    <PaperWrapped elevation={0}>
      <HeaderWrapped>
        <Box display="flex" spacing={2} alignItems="center" width="50%">
          <Typography
            className={clsx(classes.titleWrapper, 'title')}
            style={{ marginRight: 20 }}
          >
            {userFullName}
          </Typography>
          {alertValue && (
            <div className={classes.alertWrapper}>{alertValue}</div>
          )}
          {personalInfo.activeStatus && (
            <StatusWrapper
              status={personalInfo.activeStatus}
              className={classes.statusWrapper}
            >
              {personalInfo.activeStatus}
            </StatusWrapper>
          )}
        </Box>

        {newUserDetailTabs[currentTab].header}
      </HeaderWrapped>
      <TabsUI
        listTab={newUserDetailTabs}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        disabled={isEdit}
        renderLabel="label"
        renderKey="label"
      />
      {newUserDetailTabs[currentTab].body}
    </PaperWrapped>
  );
};

export default UserDetail;
