import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  makeStyles,
  Typography
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { SURVEY_ANSWER_TYPE } from '../../constants';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    marginRight: 8
  },
  wrapperItem: {
    backgroundColor: '#F6F7FA',
    padding: ' 10px 20px 0 20px'
  },
  rowItem: {
    margin: 0,
    overflowY: 'auto',
    paddingBottom: 10
  },
  dateInput: { width: 335 },
  tabInput: { width: '25%', alignItems: 'center' },
  wrapperHeader: { borderBottom: ' 2px solid #F6F7FA', padding: '8px 0px' },
  divider: {
    borderBottom: ' 2px solid #F6F7FA',
    marginTop: 20,
    marginBottom: 20
  },
  textField: {
    pointerEvents: 'none'
  },
  checkBox: {
    position: 'absolute',
    right: 0
  },
  textWrap: {
    overflowWrap: 'anywhere'
  }
});

const PhysicalPatientForm = ({
  dataPEPatient,
  setDataPEPatient,
  isEdit,
  errors
}) => {
  const classes = useStyles();
  const isCreate = !dataPEPatient.patientSubmittedDate;

  const handleChange = (optInx, sectionInx, inxItem) => e => {
    const { value, name, checked } = e.target;
    let newListPhysicalExam = [...dataPEPatient.patientJsonData];

    if (inxItem !== undefined) {
      if (name === 'patientNote') {
        newListPhysicalExam[sectionInx].questions[optInx].answers[inxItem][
          name
        ] = value;
      } else {
        if (
          newListPhysicalExam[sectionInx].questions[optInx].hasOwnProperty(
            'forceOneAnswerSelectMultipleChoice'
          )
        ) {
          newListPhysicalExam[sectionInx].questions[
            optInx
          ].answers = newListPhysicalExam[sectionInx].questions[
            optInx
          ].answers.map(item => ({ ...item, isSelected: false }));
        }

        newListPhysicalExam[sectionInx].questions[optInx].answers[inxItem][
          name
        ] = !newListPhysicalExam[sectionInx].questions[optInx].answers[inxItem][
          name
        ];

        if (!checked) {
          let newChildValue = newListPhysicalExam[sectionInx].questions.filter(
            ques =>
              ques.mapParentQuestionId ===
              newListPhysicalExam[sectionInx].questions[optInx].id
          );

          if (newChildValue) {
            newChildValue.forEach(child => {
              child.answers = child.answers?.map(item => ({
                ...item,
                isSelected: false
              }));
            });
          }
        }
      }
    } else {
      newListPhysicalExam[sectionInx].questions[
        optInx
      ].answers = newListPhysicalExam[sectionInx].questions[
        optInx
      ].answers.map(item => ({ ...item, isSelected: false }));

      newListPhysicalExam[sectionInx].questions[
        optInx
      ].answers = newListPhysicalExam[sectionInx].questions[
        optInx
      ].answers.map(item =>
        item.answer === value ? { ...item, isSelected: !item.isSelected } : item
      );
    }

    setDataPEPatient({
      ...dataPEPatient,
      patientJsonData: newListPhysicalExam
    });
  };

  const renderSectionFields = options => {
    if (isEmpty(options)) return null;
    return options?.map(option => {
      const newOtionsParent = options.find(
        item => item.id === option.mapParentQuestionId
      );
      const newOtionsChild = options.find(
        item => item.mapParentQuestionId === option.mapParentQuestionId
      );
      let newAnswersHasChild = [];

      if (newOtionsParent) {
        newAnswersHasChild = newOtionsParent.answers.concat(
          newOtionsChild.answers
        );
      }

      return option.answerType === SURVEY_ANSWER_TYPE.MultipleChoice ? (
        <>
          {!isEmpty(newAnswersHasChild) && newOtionsParent ? (
            <Grid item xs={6}>
              {option.answers.map(answer => (
                <div className={classes.wrapperItem}>
                  <div className={classes.rowItem}>
                    <span className={classes.title}>{answer.answerTitle}</span>
                    <span>
                      {isCreate || !newOtionsParent?.answers[0].isSelected
                        ? 'Nil'
                        : answer?.isSelected
                        ? 'Yes'
                        : 'No'}
                    </span>
                  </div>
                  {answer.isAdditional &&
                    answer.isSelected &&
                    !isEmpty(answer.patientNote) && (
                      <div className={classes.rowItem}>
                        <span className={classes.title}>Details:</span>
                        <span className={classes.textWrap}>
                          {answer.patientNote}
                        </span>
                      </div>
                    )}
                </div>
              ))}
            </Grid>
          ) : (
            option.answers.map(answer => (
              <Grid item xs={6}>
                <div className={classes.wrapperItem} style={{ height: '100%' }}>
                  <div className={classes.rowItem}>
                    <span className={classes.title}>{answer.answerTitle}</span>
                    <span>{answer?.isSelected ? 'Yes' : 'Nil'}</span>
                  </div>
                  {answer.isAdditional &&
                    answer.isSelected &&
                    !isEmpty(answer.patientNote) && (
                      <div
                        className={classes.rowItem}
                        style={{ display: 'inline-flex' }}
                      >
                        <span className={classes.title}>Details:</span>
                        <span className={classes.textWrap}>
                          {' '}
                          {answer.patientNote}
                        </span>
                      </div>
                    )}
                </div>
              </Grid>
            ))
          )}
        </>
      ) : (
        <Grid item xs={6}>
          <PhysicalItem data={option} key={option.label} />
        </Grid>
      );
    });
  };

  const PhysicalItem = ({ data: { question, answers } }) => {
    const newAnswer = answers.find(answer => answer.isSelected);
    return (
      <div className={classes.wrapperItem} style={{ height: '100%' }}>
        {newAnswer ? (
          <div className={classes.rowItem}>
            <span className={classes.title}>{newAnswer.answerTitle}</span>
            <span>{newAnswer?.answer || 'Nil'}</span>
          </div>
        ) : (
          <div className={classes.rowItem}>
            <span className={classes.title}>{question}</span>
            <span>{'Nil'}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    !isEmpty(dataPEPatient.patientJsonData) &&
    dataPEPatient.patientJsonData?.map((section, sectionInx) => {
      return (
        <Grid container spacing={2} key={section.category}>
          <Grid item xs={12}>
            <DividerHeader title={section.category?.toUpperCase()} />
          </Grid>

          {isEdit
            ? section.questions.map((option, optInx) => {
                const {
                  question,
                  answers,
                  answerType,
                  mapParentQuestionId
                } = option;

                const parentQues = section.questions.find(
                  question => question.id === mapParentQuestionId
                );

                return (
                  <>
                    {answerType === SURVEY_ANSWER_TYPE.MultipleChoice ? (
                      answers.map((item, inxItem) => {
                        return (
                          <Grid item xs={6}>
                            <div style={{ position: 'relative' }}>
                              <TextField
                                fullWidth
                                label={item.answerTitle}
                                id={`${item.answerTitle}-${inxItem}`}
                                defaultValue="Yes"
                                disabled={
                                  parentQues &&
                                  !parentQues.answers[0].isSelected
                                }
                                variant={
                                  parentQues &&
                                  !parentQues.answers[0].isSelected
                                    ? 'filled'
                                    : 'standard'
                                }
                                className={classes.textField}
                              />
                              <Checkbox
                                name="isSelected"
                                color="primary"
                                checked={item.isSelected}
                                className={classes.checkBox}
                                disabled={
                                  parentQues &&
                                  !parentQues.answers[0].isSelected
                                }
                                onChange={handleChange(
                                  optInx,
                                  sectionInx,
                                  inxItem
                                )}
                              />
                            </div>

                            {item.isAdditional && (
                              <TextField
                                fullWidth
                                multiline
                                rowsMax={5}
                                name="patientNote"
                                placeholder="Details"
                                disabled={!item.isSelected}
                                value={item.patientNote}
                                onChange={handleChange(
                                  optInx,
                                  sectionInx,
                                  inxItem
                                )}
                              />
                            )}
                            {errors?.patientNote &&
                              item.isAdditional &&
                              item.isSelected &&
                              isEmpty(item.patientNote) && (
                                <Typography color="error">
                                  {errors.patientNote}
                                </Typography>
                              )}

                            {errors?.atLeastChooseAnswer &&
                              parentQues &&
                              parentQues.answers[0].isSelected &&
                              !item.isSelected && (
                                <Typography color="error">
                                  {errors.atLeastChooseAnswer}
                                </Typography>
                              )}
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          label={question}
                          id={`${question}-${optInx}`}
                          value={
                            answers.find(item => item.isSelected)?.answer || ''
                          }
                          onChange={handleChange(optInx, sectionInx)}
                          disabled={
                            parentQues && !parentQues.answers[0].isSelected
                          }
                        >
                          {answers.map((item, inxItem) => (
                            <MenuItem key={inxItem} value={item.answer}>
                              {item.answer}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors?.forceAnswer && parentQues && (
                          <Typography color="error">
                            {errors.forceAnswer}
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {mapParentQuestionId && (
                      <Grid item xs={12}>
                        <div className={classes.divider}></div>
                      </Grid>
                    )}
                  </>
                );
              })
            : renderSectionFields(section.questions)}
        </Grid>
      );
    })
  );
};

export default PhysicalPatientForm;
