import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { isEmpty } from 'lodash';

import WrappedContainer from '@/components/WrappedContainer';

import PhysicalItem from './PhysicalItem';
import MedicalItem from './MedicalItem';
import DrugAllergyItem from './DrugAllergyItem';

import { PE_CATEGORY_NAME } from '../../constants';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0
  },
  title: {
    fontWeight: 600,
    marginRight: 8
  },
  detail: {
    overflowWrap: 'anywhere'
  },
  checkBox: {
    position: 'absolute',
    top: 5,
    right: 0,
    padding: 4
  }
});

const MedicalHistory = ({
  isEdit,
  medicalInfo,
  setMedicalInfo,
  errors,
  gender,
  fetchData
}) => {
  const [currentData, setCurrentData] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setCurrentData(medicalInfo);
  }, [medicalInfo]);

  useEffect(() => {
    fetchData();
  }, []);

  const { drugAllergy, physicalHistory, questionnaire } = currentData;

  return (
    <WrappedContainer>
      <div className={classes.root}>
        {!isEmpty(currentData) && (
          <Grid container spacing={2}>
            <DrugAllergyItem
              classes={classes}
              title="Drug Allergy"
              data={drugAllergy}
              isEdit={isEdit}
              medicalInfo={medicalInfo}
              setMedicalInfo={setMedicalInfo}
            />
            <MedicalItem
              classes={classes}
              title="Medical History"
              data={questionnaire?.questionnaires}
              isEdit={isEdit}
              medicalInfo={medicalInfo}
              setMedicalInfo={setMedicalInfo}
              gender={gender}
            />
            <PhysicalItem
              classes={classes}
              title="Family Medical History"
              data={physicalHistory?.patientPEJson}
              filterKey={PE_CATEGORY_NAME.FamilyHistory}
              isEdit={isEdit}
              medicalInfo={medicalInfo}
              setMedicalInfo={setMedicalInfo}
              errors={errors}
            />
          </Grid>
        )}
      </div>
    </WrappedContainer>
  );
};

export default MedicalHistory;
