import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrappedItem: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#F6F7FA',
    padding: '10px 20px'
  },

  rowItem: {
    margin: 0,
    overflowY: 'auto'
  }
});

export const WrappedItem = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrappedItem}>{children}</div>;
};
export const RowItem = ({ children }) => {
  const classes = useStyles();
  return <p className={classes.rowItem}>{children}</p>;
};
