import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputLabel,
  Checkbox,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { get, isArray, isEmpty, startCase, upperCase } from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';

const CustomTextField = withStyles({
  root: {
    pointerEvents: 'none',
    '& label + .MuiInput-formControl': {
      marginTop: 6
    },
    '& .MuiInputLabel-formControl': {
      top: -7
    }
  }
})(TextField);

const PhysicalItem = ({
  data = [],
  classes,
  title,
  filterKey,
  isEdit,
  medicalInfo,
  setMedicalInfo,
  errors
}) => {
  const [localData, setLocalData] = useState([]);

  const handleChange = id => e => {
    const { value, name } = e.target;
    const patientPEJson = medicalInfo.physicalHistory?.patientPEJson.map(
      item => {
        if (item.category === filterKey) {
          item.questions.forEach(ques => {
            ques.answers = ques.answers.map(item =>
              item.id === id
                ? name === 'patientNote'
                  ? { ...item, patientNote: value }
                  : { ...item, isSelected: !item.isSelected }
                : item
            );
          });
        }
        return item;
      }
    );

    setMedicalInfo({
      ...medicalInfo,
      physicalHistory: {
        ...medicalInfo.physicalHistory,
        patientPEJson
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(data) && isArray(data)) {
      const newData = data.find(item => item.category === filterKey);
      let answers = [];
      if (newData) {
        newData.questions.forEach(ques => {
          answers = ques.answers;
        });
      }
      setLocalData(answers);
    }
  }, [data]);

  const MedicalHistoryItem = ({ data }) => {
    return (
      <>
        <RowItem>
          <span className={classes.title}>{startCase(data.answerTitle)}:</span>
          <span>{get(data, `isSelected`) ? 'Yes' : 'Nil'}</span>
        </RowItem>
        {data.isAdditional && data.isSelected && (
          <RowItem style={{ display: 'inline-flex' }}>
            <span className={classes.title}>Detail: </span>
            <span className={classes.detail}>{get(data, `patientNote`)}</span>
          </RowItem>
        )}
      </>
    );
  };

  const renderData = () => {
    const result = [];
    localData.forEach(item => {
      const rowItem = (
        <Grid item xs={6} key={item.id}>
          {isEdit ? (
            <>
              <InputLabel style={{ fontSize: 11 }}>
                {startCase(item.answerTitle)}:
              </InputLabel>

              <div style={{ position: 'relative' }}>
                <CustomTextField fullWidth label="Yes" />
                <Checkbox
                  name="isSelected"
                  color="primary"
                  className={classes.checkBox}
                  checked={item.isSelected}
                  onChange={handleChange(item.id)}
                />
              </div>
              {item.isAdditional && (
                <TextField
                  fullWidth
                  multiline
                  rowsMax={5}
                  name="patientNote"
                  placeholder="Details"
                  disabled={!item.isSelected}
                  value={item.patientNote}
                  onChange={handleChange(item.id)}
                />
              )}
              {errors?.patientNote &&
                item.isAdditional &&
                item.isSelected &&
                isEmpty(item.patientNote) && (
                  <Typography color="error">{errors.patientNote}</Typography>
                )}
            </>
          ) : (
            <WrappedItem>
              <MedicalHistoryItem data={item} />
            </WrappedItem>
          )}
        </Grid>
      );
      result.push(rowItem);
    });
    return result;
  };

  return (
    <>
      {!isEmpty(localData) && (
        <>
          <Grid item xs={12}>
            <DividerHeader title={upperCase(title)} />
          </Grid>
          {renderData()}
        </>
      )}
    </>
  );
};

export default PhysicalItem;
