import React, { useEffect, useRef, useState } from 'react';

import TableCellFilter from '@/module/common/componentUI/TableCellFilted';
import { AllUsersAppointmentType } from '@/module/appointment-management/constants';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';

import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Box,
  withStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  GetApp as GetAppIcon,
  Visibility as VisibilityIcon,
  Email as EmailIcon
} from '@material-ui/icons';

import allUsersDispatcher from '../../action';
import { DOCUMENT_TYPES, RECORDS_COLUMN } from '../../constants';
import GroupSearch from './GroupSearch';

import moment from 'moment';
import { isEmpty } from 'lodash';

import { CheckedIcon, PaymentActive, PaymentInactive } from '@/assets/svg';
import TableData from './TableData';
import { teleAppoinmentDispatcher } from '@/module/tele-appointment';
import { blobUrl } from '@/helpers';
import PreviewPdfDialog from './PreviewPdfDialog';

const useStyle = makeStyles({
  wrapperIcon: {
    position: 'relative',
    width: 30,
    maxWidth: 30,
    marginRight: 16
  },
  checkedIcon: {
    position: 'absolute',
    right: -7,
    top: -3
  },
  buttonList: {
    margin: '0 -10px'
  },
  buttonItem: {
    padding: 10,
    '&.Mui-disabled': {
      opacity: 0.5
    }
  }
});

const StyledTableCell = withStyles({
  body: {
    verticalAlign: 'middle'
  }
})(TableCell);

const recordsPaging = {
  pageIndex: 0,
  pageSize: 10
};

const Records = ({ userId }) => {
  const classes = useStyle();

  const allRecordsRef = useRef([]);
  const [filteredRecords, setFilteredRecords] = useState([]);

  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');

  const [filterOption, setFilterOption] = useState({
    appointmentTypes: [],
    startDate: ''
  });

  const [paging, setPaging] = useState(recordsPaging);

  const [pdfLink, setPdfLink] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [typeDocument, setTypeDocument] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const filterBySearchKey = (searchKey, data) => {
    let result = [];
    const lowercaseSearchParam = searchKey?.toLowerCase();

    if (searchKey) {
      result = data?.filter(item => {
        const lowercaseProjectName = item?.projectName?.toLowerCase() || '';
        const lowercaseProjectCode = item?.projectCode?.toLowerCase() || '';
        const lowercaseLocation = item?.location?.toLowerCase() || '';

        return (
          lowercaseProjectName?.search(lowercaseSearchParam) !== -1 ||
          lowercaseProjectCode?.search(lowercaseSearchParam) !== -1 ||
          lowercaseLocation?.search(lowercaseSearchParam) !== -1
        );
      });

      return result;
    } else {
      return data;
    }
  };

  const filterByStartDate = (startDate, data) => {
    if (isEmpty(startDate)) return data;
    const startDateUnix = moment(startDate)
      .startOf('date')
      .unix();

    return data?.filter(
      item =>
        moment(item.startDate)
          .startOf('date')
          .unix() === startDateUnix
    );
  };

  const filterByAppointmentTypes = (appointmentTypesParam = [], data) => {
    if (isEmpty(appointmentTypesParam)) return data;

    return data?.filter(item => {
      // Check at least the appointment type of a record belong to the appointment types selected or not

      return item.appointmentTypes.some(apptType =>
        appointmentTypesParam.includes(apptType)
      );
    });
  };

  const filterByFilterParam = (filterParam, data) => {
    const { startDate, appointmentTypes } = filterParam;
    let result = [...data];

    result = filterByStartDate(startDate, result);
    result = filterByAppointmentTypes(appointmentTypes, result);

    return result;
  };

  const filterData = ({ searchKey, filterOption }) => {
    let filteredData = [...allRecordsRef.current];

    filteredData = filterBySearchKey(searchKey, filteredData);
    filteredData = filterByFilterParam(filterOption, filteredData);

    // Return to the first page after filtering
    setPaging(prevState => ({
      ...prevState,
      pageIndex: 0
    }));

    setFilteredRecords(filteredData);
  };

  const fetchData = () => {
    allUsersDispatcher.getRecords(userId, result => {
      /**
       * Filter data on the client site
       * Reason: BE is not support filtering data on the backend site for the records API
       */
      allRecordsRef.current = result || [];
      setFilteredRecords(result);
    });
  };

  const onFilterOptionClear = (state, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [state]: defaultValue
    });
  };

  const onFilterOptionChange = state => e => {
    setFilterOption({
      ...filterOption,
      [state]: e.target.value
    });
  };

  const onResetFilterOps = () => {
    setFilterOption({
      appointmentTypes: [],
      startDate: ''
    });
  };

  const onOpenPreview = (blob, type) => {
    if (!blob) return;

    const newBlob = blob.slice(0, blob.size, 'application/pdf');
    setPdfLink(blobUrl(newBlob));
    setOpenDialog(true);
    setTypeDocument(type);
  };

  const onPreviewDocument = row => {
    if (row.documentType === DOCUMENT_TYPES.ir) {
      teleAppoinmentDispatcher.getLinkDownloadIRReport(
        row.paxScreeningId,
        result => onOpenPreview(result, row.documentType)
      );
    } else {
      allUsersDispatcher.getLinkDownloadReceipt(row.receiptId, result =>
        onOpenPreview(result, row.documentType)
      );
    }
  };
  const onDownloadDoucument = row => {
    if (row.documentType === DOCUMENT_TYPES.ir) {
      teleAppoinmentDispatcher.downloadIRReport(row.paxScreeningId);
    } else {
      allUsersDispatcher.downloadReceipt(row);
    }
  };

  const onSendEmail = row => {
    const { paxScreeningId, documentType } = row || {};
    if (!paxScreeningId || !documentType) return;

    allUsersDispatcher.sendEmailRecord({ paxScreeningId, documentType });
  };

  const TableCellFilterWrapper = ({ ...props }) => {
    return (
      <TableCellFilter
        {...{
          hiddenRows,
          setHiddenRows
        }}
        {...props}
      />
    );
  };

  const renderDateTime = row => {
    let startDateFormat, startTimeFormat;
    const { startDate } = row;

    if (!startDate) return null;

    startDateFormat = moment(startDate).format('DD/MM/YYYY');
    startTimeFormat = moment(startDate).format('H:mm');
    return `${startDateFormat}, ${startTimeFormat}`;
  };

  const renderActionButtons = row => {
    let disableIrReceipt = false;

    if (row.documentType === DOCUMENT_TYPES.ir) {
      disableIrReceipt = !row.hasIRReport;
    } else {
      disableIrReceipt = !row?.receiptId;
    }

    return (
      <Box className={classes.buttonList}>
        <IconButton
          className={classes.buttonItem}
          onClick={() => onPreviewDocument(row)}
          disabled={disableIrReceipt}
        >
          <VisibilityIcon color="secondary" />
        </IconButton>
        <IconButton
          className={classes.buttonItem}
          onClick={() => onDownloadDoucument(row)}
          disabled={disableIrReceipt}
        >
          <GetAppIcon color="secondary" />
        </IconButton>
        <IconButton
          className={classes.buttonItem}
          onClick={() => onSendEmail(row)}
          disabled={disableIrReceipt}
        >
          <EmailIcon color="secondary" />
        </IconButton>
      </Box>
    );
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {RECORDS_COLUMN.map(({ label, stateValue, styles }) => {
          return label ? (
            <TableCellFilterWrapper
              label={label}
              stateValue={stateValue}
              key={label}
              style={styles}
            >
              {label}
            </TableCellFilterWrapper>
          ) : (
            <TableCell></TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );

  const renderTableCells = row => (
    <React.Fragment key={row.id}>
      <StyledTableCell style={{ paddingLeft: 0 }} valign="center">
        {!hiddenRows.dateTime && renderDateTime(row)}
      </StyledTableCell>
      <StyledTableCell>
        {!hiddenRows.appointmentTypes && !isEmpty(row.appointmentTypes)
          ? row.appointmentTypes
              .map((appType, index) => AllUsersAppointmentType[appType])
              .join(', ')
          : null}
      </StyledTableCell>
      <StyledTableCell>
        {!hiddenRows.totalCost && formatTotalPrice(row.totalCost, '$')}
      </StyledTableCell>
      <StyledTableCell>
        {!hiddenRows.paymentStatus && row.paxScreenPaymentStatus && (
          <Tooltip title="Payment Status">
            {row.paxScreenPaymentStatus === 'Paid' ? (
              <div className={classes.wrapperIcon}>
                <CheckedIcon className={classes.checkedIcon} />
                <PaymentActive />
              </div>
            ) : (
              <div className={classes.wrapperIcon}>
                <PaymentInactive />
              </div>
            )}
          </Tooltip>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {!hiddenRows.documentType && row.documentType}
      </StyledTableCell>
      <StyledTableCell>{renderActionButtons(row)}</StyledTableCell>
    </React.Fragment>
  );

  return (
    <>
      <TableData
        data={filteredRecords}
        header={TableHeader}
        paging={paging}
        setPaging={setPaging}
        filterOption={filterOption}
        renderRow={row => renderTableCells(row)}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterData={filterData}
        ToolbarComponent={
          <GroupSearch
            {...{
              onFilterOptionClear,
              onFilterOptionChange,
              filterOption,
              setFilterOption,
              onResetFilterOps
            }}
          />
        }
      />
      {openDialog && (
        <PreviewPdfDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          pdfLink={pdfLink}
          title={`View ${typeDocument}`}
        />
      )}
    </>
  );
};

export default Records;
