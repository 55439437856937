import {
  USER_GENDER,
  FORMAT_DD_MM_YYYY,
  ID_TYPE,
  ETHNIC_GROUP,
} from '../../constants';
import { DetailWrapped, WrapperButtonUpload } from './styled';
import { AddImageOutlineIcon } from '@/assets/svg';
import PhoneInput from '@/components/PhoneInput';
import { doctorStatus } from '@/enum/RequestEnum';
import { renderUnitNumber } from '@/helpers';
import { UploadSingle } from '@/module/common/component';
import ControlDatepicker from '@/module/common/component/ControlDatepicker';
import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { NATIONALITY } from '@/module/patient/constants';
import {
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { isEmpty, get, cloneDeep, set } from 'lodash';
import React, { useEffect } from 'react';

const PersonalInformation = ({
  personalInfo,
  setPersonalInfo,
  errors,
  lstIdentityUrl,
  isEdit,
}) => {
  const handleChange = (key) => (e, valueSelect) => {
    let value;
    switch (key) {
      case 'dateOfBirth':
        value = e.toISOString();
        break;
      case 'nationality':
        value = valueSelect;
        break;
      case 'postalCode':
        // Regex labnum number contain only number without dot and comma symbol
        const regexNumber = /^[0-9\b]+$/;
        let newValue = get(e, 'target.value');
        if (regexNumber.test(newValue)) value = newValue;
        break;
      default:
        value = get(e, 'target.value');
        break;
    }
    setPersonalInfo({
      ...personalInfo,
      [key]: value,
      countryCode: isEmpty(personalInfo.countryCode)
        ? 'SG'
        : key === 'countryCode'
        ? value
        : personalInfo.countryCode,
    });
  };

  const handleChangeIdentityType = (e) => {
    setPersonalInfo({
      ...personalInfo,
      identityType: e.target.value,
      nric: '',
    });
  };

  const handleChangeAddress = (data) => {
    setPersonalInfo({
      ...personalInfo,
      address: data.description || '',
      latitude: data.lat || 0,
      longitude: data.lng || 0,
    });
  };
  const handleChangeIdentity = (data, field) => {
    const payload = cloneDeep(personalInfo);
    set(payload, field, data);
    setPersonalInfo(payload);
  };

  const unitNumber = renderUnitNumber(
    personalInfo.floor,
    personalInfo.unitNumber
  );

  return (
    <DetailWrapped>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DividerHeader title="General Information" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Full Name"
              value={personalInfo.fullName || ''}
              error={!!errors.fullName}
              helperText={errors.fullName}
              onChange={handleChange('fullName')}
              disabled={!isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Minc ID"
              value={personalInfo.patientShortId || ''}
              disabled
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="id-label-identity" disabled={!isEdit}>
              Type of ID
            </InputLabel>
            <Select
              fullWidth
              labelId="id-label-identity"
              value={personalInfo.identityType || ''}
              onChange={handleChangeIdentityType}
              disabled={!isEdit}
            >
              {ID_TYPE.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
            {errors.identityType && (
              <Typography color="error">{errors.identityType}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="NRIC/Passport"
              value={personalInfo.nric || ''}
              error={!!errors.nric}
              helperText={errors.nric}
              onChange={handleChange('nric')}
              disabled={!isEdit}
            />
          </Grid>

          {personalInfo.approveStatus === doctorStatus.Approved ? null : (
            <Grid container spacing={4} style={{ margin: 0 }}>
              <Grid item xs={6}>
                <InputLabel style={{ marginBottom: 8 }} disabled={!isEdit}>
                  Front of NRIC
                </InputLabel>
                <UploadSingle
                  typeUpload="normal"
                  accept=".jpg,.png,.jpeg"
                  onChange={(data) =>
                    handleChangeIdentity(data, 'lstIdentityUrl[0]')
                  }
                  imageUrlSelected={
                    !isEmpty(lstIdentityUrl) && lstIdentityUrl[0]
                  }
                  uploadButton={
                    <WrapperButtonUpload>
                      <span className="icon">
                        <AddImageOutlineIcon color="#ccc" />
                      </span>{' '}
                      <span className="text">Add ID Picture</span>
                    </WrapperButtonUpload>
                  }
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel style={{ marginBottom: 8 }} disabled={!isEdit}>
                  Back of NRIC
                </InputLabel>
                <UploadSingle
                  typeUpload="normal"
                  accept=".jpg,.png,.jpeg"
                  onChange={(data) =>
                    handleChangeIdentity(data, 'lstIdentityUrl[1]')
                  }
                  imageUrlSelected={
                    !isEmpty(lstIdentityUrl) && lstIdentityUrl[1]
                  }
                  uploadButton={
                    <WrapperButtonUpload>
                      <span className="icon">
                        <AddImageOutlineIcon color="#ccc" />
                      </span>{' '}
                      <span className="text">Add ID Picture</span>
                    </WrapperButtonUpload>
                  }
                  disabled={!isEdit}
                />
              </Grid>
              {errors.lstIdentityUrl && (
                <Typography style={{ marginLeft: 16 }} color="error">
                  {errors.lstIdentityUrl}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            <InputLabel id="id-label-gender" disabled={!isEdit}>
              Gender
            </InputLabel>
            <Select
              fullWidth
              labelId="id-label-gender"
              value={personalInfo.gender || ''}
              onChange={handleChange('gender')}
              disabled={!isEdit}
            >
              {USER_GENDER.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {errors.gender && (
              <Typography color="error">{errors.gender}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <ControlDatepicker
              value={personalInfo.dateOfBirth}
              onChange={handleChange('dateOfBirth')}
              label="Date Of Birth"
              error={errors.dateOfBirth}
              inputFormat={FORMAT_DD_MM_YYYY}
              disableFuture
              grid={12}
              disabled={!isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={NATIONALITY}
              value={personalInfo.nationality || ''}
              autoComplete
              onChange={handleChange('nationality')}
              renderInput={(params) => (
                <TextField {...params} label="Nationality" />
              )}
              disabled={!isEdit}
            />
            {errors.nationality && (
              <Typography color="error">{errors.nationality}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="id-ethnic-group" disabled={!isEdit}>
              Ethnic Group
            </InputLabel>
            <Select
              fullWidth
              labelId="id-ethnic-group"
              value={personalInfo.ethnicGroup || ''}
              onChange={handleChange('ethnicGroup')}
              disabled={!isEdit}
            >
              {ETHNIC_GROUP.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {errors.ethnicGroup && (
              <Typography color="error">{errors.ethnicGroup}</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Alerts"
              value={personalInfo.alert || ''}
              onChange={handleChange('alert')}
              disabled={!isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              fullWidth
              label="Notes"
              value={personalInfo.note || ''}
              onChange={handleChange('note')}
              disabled={!isEdit}
              rowsMax={5}
            />
          </Grid>

          <Grid item xs={12}>
            <DividerHeader title="Contact" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              value={personalInfo.email || ''}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleChange('email')}
              disabled={!isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={!personalInfo.isParent || !isEdit}
              error={!!errors.mobile}
              helperText={errors.mobile}
              id="phone-input"
              label="Mobile number"
              value={personalInfo.mobile}
              onChange={handleChange('mobile')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneInput
                      country={personalInfo.countryCode}
                      disabled={!personalInfo.isParent || !isEdit}
                      onChange={handleChange('countryCode')}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Postal Code"
              value={personalInfo.postalCode || ''}
              onChange={handleChange('postalCode')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S</InputAdornment>
                ),
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 6);
              }}
              disabled={!isEdit}
              error={errors.postalCode}
              helperText={errors.postalCode}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="City"
              value={personalInfo.city || ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address Line 1"
              value={personalInfo.address || ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Address Line 2"
              value={unitNumber || ''}
              disabled
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </DetailWrapped>
  );
};

export default PersonalInformation;
