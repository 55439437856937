import styled from 'styled-components';

export const PaperWrapped = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${props =>
    props.elevation === 0
      ? 'none'
      : `0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)`};
  margin-bottom: 20px;
`;

export const HeaderWrapped = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .title {
    color: #192637;
    font-size: 18px;
    font-weight: 600;
  }
  .personal-btn {
    display: flex;
  }
`;

export const DetailWrapped = styled.div`
  padding: 20px;
`;

export const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
  min-height: 200px;
`;
