import React from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles({
  root: {
    width: 'fit-content',
    height: 35,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    padding: '10px 15px',
    margin: 10,
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 35,
    color: '#192637',
    fontSize: 12,
    lineHeight: '21px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  active: {
    border: '2px solid #AD5E99',
    color: '#AD5E99'
  },
  inActive: {
    border: '2px solid #8F9BA9',
    color: '#8F9BA9'
  }
});

const CustomChip = ({ selectedDate: value, onChange, label, ...props }) => {
  const classes = useStyles();
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        onChange(label);
      }}
      className={classnames(
        classes.root,
        value === label ? classes.active : classes.inActive
      )}
    >
      {label}
    </div>
  );
};

export default CustomChip;
