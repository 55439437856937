import React from 'react';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: props => props.width,
    borderColor: '#F6F7FA'
  },
  disablePaddingX: {
    paddingRight: 0,
    paddingLeft: 0
  },
  disablePaddingY: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

export default function CustomTableCell({
  width,
  disablePaddingX,
  disablePaddingY,
  className,
  ...props
}) {
  const classes = useStyles({ width });

  return (
    <TableCell
      className={`${className || ''} ${classes.root} ${
        disablePaddingX ? classes.disablePaddingX : ''
      } ${disablePaddingY ? classes.disablePaddingY : ''}`}
      {...props}
    />
  );
}
