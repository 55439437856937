import { synthesize } from 'redux-dispatcher';
import { createDownloadableLink } from '@/helpers';
import moment from 'moment';
import { NAME_DOWNLOAD_TYPE } from '../constants';
import { isEmpty } from 'lodash';
import globalDispatcher from '@/redux/global/actions';
import { uploadImage } from '@/firebase';

const mapDispatchToAC = {
  getAllUsersSuccess: data => ({ data }),
  getAllUsers: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().allUsers.paging;
    let { result, status } = await Api.post(`Homescreens/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && allUsersDispatcher.getAllUsersSuccess(result);
  },
  getUserDetail: (id, callback) => async ({ Api }) => {
    const { status, result } = await Api.get(`Homescreens/${id}`);
    if (status === 200) {
      callback && callback(result);
    }
  },
  editPersonalInfo: (id, data) => async ({ Api, customToast }) => {
    //convert lstIdentityUrl
    allUsersDispatcher.setState('loading', true);
    let corporateBackPhoto = '';
    let corporateFrontPhoto = '';
    let lstIdentityUrl = [];

    const identityCorporateBack = data?.corporateBackPhoto;
    const identityCorporateFront = data?.corporateFrontPhoto;

    //convert corporateBackPhoto and corporateFrontPhoto
    if (identityCorporateBack?.constructor === File) {
      corporateBackPhoto = await uploadImage(identityCorporateBack);
    } else corporateBackPhoto = identityCorporateBack;

    if (identityCorporateFront?.constructor === File) {
      corporateFrontPhoto = await uploadImage(identityCorporateFront);
    } else corporateFrontPhoto = identityCorporateFront;

    for (let i = 0; i < data.lstIdentityUrl?.length; i++) {
      let url = '';
      const identity = data.lstIdentityUrl[i];
      if (identity?.constructor === File) {
        url = await uploadImage(identity, null, true);
        lstIdentityUrl.push(url);
      } else {
        identity && lstIdentityUrl.push(identity);
      }
    }

    const { status } = await Api.put(`Homescreens/user/${id}`, {
      ...data,
      lstIdentityUrl,
      corporateBackPhoto,
      corporateFrontPhoto
    });
    allUsersDispatcher.setState('loading', false);
    status === 200 && customToast('success', 'Edit success');
  },
  getPhysicalHistories: (patientId, callback, paging) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().allUsers.paging;
    let { result, status } = await Api.post(`PhysicalHistories/filter`, {
      patientId,
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search: ''
    });
    status === 200 && callback(result);
  },
  setState: (state, value) => ({ state, value }),
  getAllMedicalHistory: (id, cb) => async ({ Api, customToast }) => {
    const response = await Api.get(`MedicalHistories`, {
      patientId: id
    });
    response.status === 200 && cb && cb(response.result);
  },
  getExportExcel: search => async ({ Api }) => {
    let { result, status } = await Api.post(`Homescreens/csv-report`, {
      search
    });
    status === 200 && createDownloadableLink(result, 'user-homescreen');
  },
  getSurveyHistory: (params, callback = () => null) => async ({ Api }) => {
    const { patientId, paxScreeningId } = params || {};

    let { result, status } = await Api.get(`SurveyHistories`, {
      patientId,
      paxScreeningId
    });
    status === 200 && callback(result);
  },
  updateDoctorPE: (prarams, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.put(
      `PhysicalHistories/doctor-submit`,
      prarams
    );
    if (status === 200) {
      callback && callback();
      customToast('success', 'Edit success');
    }
  },
  getFamilyGroups: ({ userId, paging }, callback = () => null) => async ({
    Api
  }) => {
    let { result, status } = await Api.get(`FamilyGroups`, {
      userId,
      pageIndex: paging?.pageIndex ?? 0,
      pageSize: paging?.pageSize ?? 10
    });
    status === 200 && callback(result);
  },
  getRecords: (patientId, callback = () => null) => async ({ Api }) => {
    let { result, status } = await Api.get(`Homescreens/records`, {
      patientId
    });
    status === 200 && callback(result);
  },
  downloadReceipt: (params, callback = () => null) => async ({
    Api,
    customToast
  }) => {
    const { status, result } = await Api.get(
      `Receipts/${params.receiptId}/download`
    );

    const fileName = `${NAME_DOWNLOAD_TYPE[params.receiptType]} - ${moment(
      params.startDate
    ).format('DD MMM YYYY')} - ${Date.now()}`;

    if (status === 200) {
      customToast('success', 'Download success');
      createDownloadableLink(result, fileName, 'pdf');
    }
  },
  getLinkDownloadReceipt: (receiptId, callback = () => null) => async ({
    Api
  }) => {
    let { result, status } = await Api.get(`Receipts/${receiptId}/download`);
    status === 200 && callback(result);
  },
  sendEmailRecord: ({ paxScreeningId, documentType }) => async ({
    Api,
    customToast
  }) => {
    const { result, status } = await Api.post('Homescreens/email-record', {
      paxScreeningId,
      documentType
    });

    if (status === 200) {
      customToast('success', 'Send email successfully');
    }
  },
  getAllUsersAppointments: (
    search,
    paging,
    sortOption = {},
    filterOption = {},
    patientId
  ) => async ({ Api, getState }) => {
    let pagingData = paging || getState().allUsers.paging;
    let { result, status } = await Api.post(`Homescreens/appointments`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...sortOption,
      ...filterOption,
      patientId
    });
    status === 200 &&
      allUsersDispatcher.getAllUsersAppointmentsSuccess(
        'listAllUsersAppointment',
        result
      );
  },

  getAllUsersAppointmentsSuccess: (name, data) => ({ [name]: data }),
  resetData: () => ({}),
  getMedicalInfoByID: (patientId, callback) => async ({ Api }) => {
    let { result, status } = await Api.get(
      `Patients/${patientId}/medical-info`
    );
    status === 200 && callback(result);
  },
  updateMedicalInfo: (patientId, prarams, callback) => async ({
    Api,
    customToast
  }) => {
    let { status } = await Api.put(
      `Patients/${patientId}/medical-info`,
      prarams
    );
    if (status === 200) {
      callback && callback();
      customToast('success', 'Edit success');
    }
  }
};

const allUsersDispatcher = synthesize('allUsers', mapDispatchToAC);
export default allUsersDispatcher;
