import allUsersDispatcher from '../action';

const initialState = {
  list: [],
  listAllUsersAppointment: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const allUsersReducer = allUsersDispatcher(initialState, {
  [allUsersDispatcher.getAllUsersSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [allUsersDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [allUsersDispatcher.getAllUsersAppointmentsSuccess]: (state, payload) => ({
    listAllUsersAppointment: payload.listAllUsersAppointment.list,
    paging: {
      ...payload.listAllUsersAppointment,
      list: undefined
    }
  }),
  [allUsersDispatcher.resetData]: (state, payload) => ({
    ...initialState
  })
});

export default allUsersReducer;
