import React, { useState } from 'react';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton,
  Modal,
  Typography
} from '@material-ui/core';
import {
  CreateRounded,
  Delete,
  DeleteRounded,
  GetApp
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MenuAction from '../../../components/MenuAction';
import allUsersDispatcher from '../action';
import { useHistory } from 'react-router-dom';
import { urlLabel } from '@/enum/PermissionEnum';
import moment from 'moment';
import { isEmpty } from 'lodash';
import customToast from '@/new-components/CustomNotification';
import { getNationalNumber } from '@/helpers';
import { renderUsersColumn } from '../constants';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import { UpcomingAppointment } from '@/assets/svg';
import { StatusWrapper } from '@/module/common/componentUI/commonStyleComponents';
import patientDispatcher from '@/module/patient/actions';
import ModalPresenter from '@/components/ModalPresenter';

const AllUsers = () => {
  const { list, paging } = useSelector(state => state.allUsers);
  const history = useHistory();

  const [itemSelected, setItemSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const tableColumn = renderUsersColumn();

  const onShowAllUsers = itemSelected => {
    history.push({
      pathname: `/${urlLabel.editUserDetail}`,
      state: itemSelected,
      search: 'user=' + itemSelected.id
    });
  };

  const exportExcel = () => {
    if (list.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    allUsersDispatcher.getExportExcel(searchKey);
  };

  const exportLatestIRReport = patientId => {
    patientDispatcher.getLatestIRReport(patientId);
  };

  const handleDeletePatient = () => {
    patientDispatcher.deletePatient(itemSelected.id, () =>
      allUsersDispatcher.getAllUsers(searchKey, paging)
    );
    setShowModalConfirm(false);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFiltedWrapper
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
        <TableCell align="right">
          <IconButton
            style={{ marginBottom: -12 }}
            aria-label="export"
            onClick={exportExcel}
          >
            <GetApp />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const listActions = [
    {
      key: 'detail',
      icon: CreateRounded,
      label: 'Detail',
      onClick: onShowAllUsers
    }
  ];

  const renderTableBody = row => {
    return (
      <>
        <FiltedBodyCell
          style={{ cursor: 'pointer' }}
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            onShowAllUsers(row);
          }}
        >
          {!hiddenRows.name && row.fullName}
        </FiltedBodyCell>
        <FiltedBodyCell>{!hiddenRows.nric && row.nric}</FiltedBodyCell>

        <FiltedBodyCell>
          {!hiddenRows.dateOfBirth &&
            row.dateOfBirth &&
            moment(row.dateOfBirth).format('DD/MM/yyyy')}
        </FiltedBodyCell>
        <FiltedBodyCell>
          {!hiddenRows.mobile &&
            row.mobile &&
            getNationalNumber(row.mobile, row.countryCode, true)}
        </FiltedBodyCell>
        <FiltedBodyCell>{!hiddenRows.email && row.email}</FiltedBodyCell>
        <TableCell>
          {!hiddenRows.activeStatus && (
            <StatusWrapper status={row.activeStatus}>
              {row.activeStatus}
            </StatusWrapper>
          )}
        </TableCell>

        <FiltedBodyCell>
          {!hiddenRows.lastestIR && (
            <IconButton
              disabled={!row.hasIRReport}
              onClick={() => exportLatestIRReport(row.id)}
            >
              <GetApp />
            </IconButton>
          )}
        </FiltedBodyCell>

        <FiltedBodyCell>
          {!hiddenRows.upcomingAppointment && row.hasUpcomingAppointment && (
            <UpcomingAppointment className="disabled-pointer-events" />
          )}
        </FiltedBodyCell>

        <FiltedBodyCell align="right">
          <IconButton
            onClick={e => {
              setShowModalConfirm(true);
              setItemSelected(row);
            }}
          >
            <Delete />
          </IconButton>
        </FiltedBodyCell>
      </>
    );
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="All Users"
        paging={paging}
        data={list}
        fullWidthSearch
        searchPlaceholder="User Name, User ID, NRIC, Email, Mobile Number..."
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={allUsersDispatcher.getAllUsers}
        renderRow={row => renderTableBody(row)}
      />

      {showModalConfirm && (
        <Modal
          open={showModalConfirm}
          onClose={() => setShowModalConfirm(false)}
        >
          <div>
            <ModalPresenter
              onClose={() => setShowModalConfirm(false)}
              Icon={
                <DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title="Delete User?"
              onClick={handleDeletePatient}
            >
              <Typography>Click “Continue” to delete</Typography>
              <Typography>
                <Typography style={{ fontWeight: 600 }} display={'inline'}>
                  {itemSelected.fullName}
                </Typography>
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AllUsers;
