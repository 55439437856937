import React, { useEffect, useCallback } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Paper,
  TableContainer as MUTableContainer,
  TableBody,
  TablePagination,
  TableRow,
  IconButton,
  Grid
} from '@material-ui/core';
import { debounce } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  LastPage,
  FirstPage,
  KeyboardArrowRight,
  KeyboardArrowLeft
} from '@material-ui/icons';
import styled from 'styled-components';

const useStyles = makeStyles({
  head: {
    fontWeight: 600
  },
  container: {
    height: '100%',
    width: '100%'
  },
  tableContainer: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    margin: 0,
    padding: '0 20px',
    overflow: 'auto'
  },
  paginationRoot: {
    padding: '20px 0',
    '&.MuiTablePagination-root:last-child': {
      padding: '20px 0'
    }
  }
});

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

const TableData = ({
  header: Header,
  renderRow,
  paging = {},
  setPaging,
  filterOption,
  data = [],
  filterData,
  setSearchKey,
  searchKey,
  searchPlaceholder = 'Search',
  ToolbarComponent
}) => {
  const classes = useStyles();
  const { pageIndex, pageSize } = paging;

  const onChangeSearch = e => {
    setSearchKey(e.target.value);
  };

  const debounceLoadData = useCallback(
    debounce(({ searchKey, filterOption }) => {
      filterData({ searchKey, filterOption });
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData({ searchKey, filterOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const TablePaginationActions = props => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;

    const handleFirstPageButtonClick = () => {
      setPaging(prevState => ({
        ...prevState,
        pageIndex: 0
      }));
    };

    const handleBackButtonClick = () => {
      setPaging(prevState => ({
        ...prevState,
        pageIndex: page - 1
      }));
    };

    const handleNextButtonClick = () => {
      setPaging(prevState => ({
        ...prevState,
        pageIndex: page + 1
      }));
    };

    const handleLastPageButtonClick = () => {
      const totalPage = Math.ceil(data.length / paging.pageSize);

      setPaging(prevState => ({
        ...prevState,
        pageIndex: totalPage - 1
      }));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  };

  const renderToolsBar = () => (
    <Grid container style={{ padding: '20px 20px 0' }}>
      <Grid item xs={12} className="search-container">
        <Input
          allowClear
          value={searchKey}
          onChange={onChangeSearch}
          placeholder={searchPlaceholder}
          prefix={
            <SearchOutlined
              style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }}
              type="search"
            />
          }
        />
      </Grid>
      {!!ToolbarComponent && (
        <Grid item xs={12} style={{ marginTop: 16 }}>
          {ToolbarComponent}
        </Grid>
      )}
    </Grid>
  );

  return (
    <Paper className={classes.root} elevation={0}>
      <MUTableContainer className={classes.container}>
        <ToolsBar>{renderToolsBar()}</ToolsBar>
        <div className={classes.tableContainer}>
          <Table stickyHeader>
            {Header && <Header />}
            <TableBody>
              {(data || [])
                .slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
                .map((d, i) => (
                  <TableRow
                    key={d.id}
                    hover
                    classes={{
                      head: classes.head
                    }}
                  >
                    {renderRow(d, i)}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </MUTableContainer>
      <TablePagination
        className={classes.paginationRoot}
        rowsPerPageOptions={[5, 10, 50, 100, 200]}
        component="div"
        count={data.length || 0}
        rowsPerPage={paging.pageSize}
        page={paging.pageIndex}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onChangePage={(e, page) => {
          e &&
            setPaging(prevState => ({
              ...prevState,
              pageIndex: page
            }));
        }}
        onChangeRowsPerPage={e => {
          setPaging(prevState => ({
            ...prevState,
            pageSize: e.target.value
          }));
        }}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default TableData;

const ToolsBar = styled.div`
  .justify-between {
    justify-content: space-between;
  }
`;
