import React from 'react';
import { TeleAppointmentManagement } from '@/module/tele-appointment';
import { appointmentColNames } from '@/module/tele-appointment/constants';

const UserAppointmentCols = [
  [appointmentColNames.dateTime],
  [appointmentColNames.appointmentTypes],
  [appointmentColNames.location],
  [appointmentColNames.status],
  [appointmentColNames.totalCost],
  [appointmentColNames.paymentStatus]
];

const UserAppointments = ({ userId }) => {
  return (
    <TeleAppointmentManagement
      userId={userId}
      showToolbar={false}
      headerCols={UserAppointmentCols}
      hideExportExcel
    />
  );
};

export default UserAppointments;
